import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  FormControl,
  FormLabel,
  FormGroup,
  Container,
  Button,
  InputLabel,
  Select,
  MenuItem,
  Autocomplete
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useTranslation } from '../common/components/LocalizationProvider';
import SettingsMenu from './components/SettingsMenu';
import { useCatch } from '../reactHelper';
import fetchFunc from '../fetchFunc';
import { useSelector } from 'react-redux';
import useQuery from '../common/util/useQuery';
import { useNavigate, useParams } from 'react-router-dom';
import { simpleCalendar, parseRule, formatTime, formatRule, updateCalendar } from './CalendarPage'
import PageLayout from '../common/components/PageLayout';
import { prefixString } from '../common/util/stringUtils';
import { useEffectAsync } from '../reactHelper';
import Cookies from 'js-cookie';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-evenly',
    '& > *': {
      flexBasis: '33%',
    },
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    paddingBottom: theme.spacing(3),
  },
  emailTips: {
    fontWeight: '500',
  }
}));

const DevicePage = () => {
  const classes = useStyles();
  const t = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const devices = useSelector((state) => state.devices.items);

  const query = useQuery();
  const uniqueId = query.get('uniqueId')

  const currentUser = useSelector((state) => state.session.user);

  const [item, setItem] = useState();
  const [geofences, setGeofences] = useState([]);

  const decoded = item && item.tcCalendars && item.tcCalendars.data && window.atob(item.tcCalendars.data);

  const lines = decoded && decoded.split('\n')

  const rule = lines && parseRule(lines[7]);

  const addBind = async () => {
    const response = await fetch(`${process.env.REACT_APP_URL_ATOTO}/atoto-gps-core/gps/customer/rebind`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: currentUser.email, name: item.name, psw: atob(query.get('psw')), uniqueId }),
    });
    if (response.ok) {
      const res = await response.json()
      if (res.code === 200) {
        navigate('/');
      }
    } else {
      throw Error(await response.text());
    }
  }

  const edit = async () => {
    const params = {
      id: item.id,
      name: item.name,
      calendarData: item.notificationTypes.some(item=>['geofenceEnter', 'geofenceExit'].includes(item)) ? item.tcCalendars?.data : undefined,
      notificationTypes: item.notificationTypes,
      geofenceIds: item.tcGeofences && item.tcGeofences.map((item) => item.id),
      notificators: item.notificators,
    }
    const response = await fetch(`${process.env.REACT_APP_URL_ATOTO}/atoto-gps-core/webWrap/api/devices`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', "JSESSIONID": Cookies.get('JSESSIONID') },
      body: JSON.stringify(params),
      // credentials: 'include',
      withCredentials: true,
    });
    if (response.ok) {
      const res = await response.json()
      if (res.code === 200) {
        navigate(-1);
      }
    } else {
      throw Error(await response.text());
    }
  }

  const handleSave = useCatch(async () => {
    !item.id ? addBind() : edit() 
  });

  const getDetails = useCatch(async () => {
    const response = await fetch(`${process.env.REACT_APP_URL_ATOTO}/atoto-gps-core/webWrap/api/devices?id=${id}`);
    if (response.ok) {
      const res = await response.json()
      if (res.code === 200 && res.data) {
        const { tcCalendars } = res.data;
        setItem({ 
          ...res.data, 
          tcCalendars: (tcCalendars && tcCalendars.data) ? res.data.tcCalendars : { data: simpleCalendar() },
        })
      }
    } else {
      throw Error(await response.text());
    }
  })

  const handleEventTypes = (eventType, checked) => {
    if (checked) {
      setItem({ ...item, notificationTypes: [...(item.notificationTypes || []), eventType] })
    } else {
      setItem({ ...item, notificationTypes: item.notificationTypes.filter((item) => item !== eventType) })
    }
  }

  const validate = () => {
    if(uniqueId) return item && item.name 
    return item && item.id && item.name
  }

  useEffect(() => {
    if (uniqueId) setItem({ ...item, uniqueId, name: `unknown${Object.keys(devices).length}` })
  }, [uniqueId, devices])

  useEffect(() => {
    if (id) getDetails()
  }, [id])

  useEffectAsync(async () => {
      const response = await fetchFunc('/api/geofences');
      if (response.ok) {
        setGeofences(await response.json());
      } else {
        throw Error(await response.text());
      }
  }, []);

  return (
    <PageLayout menu={<SettingsMenu />} breadcrumbs={['sharedDevice']}>
      <Container maxWidth="xs" className={classes.container}>
        {item && (
          <>
            <Accordion defaultExpanded>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle1">
                  {t('sharedRequired')}
                </Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.details}>
                <TextField
                  value={item.name || ''}
                  onChange={(event) => setItem({ ...item, name: event.target.value })}
                  label={t('sharedName')}
                />
              </AccordionDetails>
            </Accordion>

            {item.id && (
              <Accordion defaultExpanded>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="subtitle1">
                    {t('chooseRelevantGeofences')}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.details}>

                  <Autocomplete
                    isOptionEqualToValue={(i1, i2) => i1.id === i2.id}
                    options={geofences || []}
                    getOptionLabel={(item) => item.name}
                    renderInput={(params) => <TextField {...params} label={t('sharedGeofences')} />}
                    value={item.tcGeofences || []}
                    onChange={(_, value) => setItem({ ...item, tcGeofences: value })}
                    multiple
                  />

                  <FormGroup>
                    <FormLabel>Geofence Reminder</FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.notificationTypes && item.notificationTypes.includes('geofenceEnter')}
                          onChange={(e) => handleEventTypes('geofenceEnter', e.target.checked)}
                        />
                      }
                      label="Enter Geofence"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.notificationTypes && item.notificationTypes.includes('geofenceExit')}
                          onChange={(e) => handleEventTypes('geofenceExit', e.target.checked)}
                        />
                      }
                      label="Exit Geofence"
                    />
                  </FormGroup>

                  {item.notificationTypes && (item.notificationTypes.includes('geofenceEnter') || item.notificationTypes.includes('geofenceExit')) ? <>
                    <FormLabel>Remind Time</FormLabel>
                    <TextField
                      label={t('reportFrom')}
                      type="datetime-local"
                      value={moment(lines[5].slice(-15)).locale('en').format(moment.HTML5_FMT.DATETIME_LOCAL)}
                      onChange={(e) => {
                        const time = formatTime(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL));
                        setItem({ ...item, tcCalendars: { data: updateCalendar(lines, 5, `DTSTART;${time}`) } });
                      }}
                    />
                    <TextField
                      label={t('reportTo')}
                      type="datetime-local"
                      value={moment(lines[6].slice(-15)).locale('en').format(moment.HTML5_FMT.DATETIME_LOCAL)}
                      onChange={(e) => {
                        if (moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL).isBefore(moment(lines[5].slice(-15)).locale('en'))) return;
                        const time = formatTime(moment(e.target.value, moment.HTML5_FMT.DATETIME_LOCAL));
                        setItem({ ...item, tcCalendars: { data: updateCalendar(lines, 6, `DTEND;${time}`) } });
                      }}
                    />
                    <FormControl>
                      <InputLabel>{t('calendarRecurrence')}</InputLabel>
                      <Select
                        label={t('calendarRecurrence')}
                        value={rule.frequency}
                        onChange={(e) => setItem({ ...item, tcCalendars: { data: updateCalendar(lines, 7, formatRule({ frequency: e.target.value })) } })}
                      >
                        {['ONCE', 'DAILY', 'WEEKLY', 'MONTHLY'].map((it) => (
                          <MenuItem key={it} value={it}>{t(prefixString('calendar', it.toLowerCase()))}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {['WEEKLY', 'MONTHLY'].includes(rule.frequency) && (
                      <FormControl>
                        <InputLabel>{t('calendarDays')}</InputLabel>
                        <Select
                          multiple
                          label={t('calendarDays')}
                          value={rule.by}
                          onChange={(e) => setItem({ ...item, tcCalendars: { data: updateCalendar(lines, 7, formatRule({ ...rule, by: e.target.value })) } })}
                        >
                          {rule.frequency === 'WEEKLY' ? ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'].map((it) => (
                            <MenuItem key={it} value={it.substring(0, 2).toUpperCase()}>{t(prefixString('calendar', it))}</MenuItem>
                          )) : Array.from({ length: 31 }, (_, i) => i + 1).map((it) => (
                            <MenuItem key={it} value={it}>{it}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </> : null}

                  <FormGroup>
                    <FormLabel>Device Reminder</FormLabel>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.notificationTypes && item.notificationTypes.includes('deviceOnline')}
                          onChange={(e) => handleEventTypes('deviceOnline', e.target.checked)}
                        />
                      }
                      label="Device Online"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={item.notificationTypes && item.notificationTypes.includes('deviceOffline')}
                          onChange={(e) => handleEventTypes('deviceOffline', e.target.checked)}
                        />
                      }
                      label="Device Offline"
                    />
                  </FormGroup>

                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={item.notificators && item.notificators.includes('mail')}
                        onChange={(e) => setItem({ ...item, notificators: e.target.checked ? ['mail'] : undefined })}
                      />
                    }
                    label={<span className={classes.emailTips}>Email Notification</span>}
                  />

                </AccordionDetails>
              </Accordion>
            )}
          </>
        )}
        <div className={classes.buttons}>
          <Button
            type="button"
            color="primary"
            variant="outlined"
            onClick={() => navigate(-1)}
            disabled={!item}
          >
            {t('sharedCancel')}
          </Button>
          <Button
            type="button"
            color="primary"
            variant="contained"
            onClick={handleSave}
            disabled={!item || !validate()}
          >
            {t('sharedSave')}
          </Button>
        </div>
      </Container>
    </PageLayout>
  )
};

export default DevicePage;
