import React, { useState } from 'react';
import {
    Container, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import userDelete_es1 from '../../resources/images/atoto/userDelete_es1.png';
import userDelete_es2 from '../../resources/images/atoto/userDelete_es2.png';
import userDelete_es3 from '../../resources/images/atoto/userDelete_es3.png';
import userDelete_es4 from '../../resources/images/atoto/userDelete_es4.png';
import userDelete_mobile_es1 from '../../resources/images/atoto/userDelete_mobile_es1.png';
import userDelete_mobile_es2 from '../../resources/images/atoto/userDelete_mobile_es2.png';
import userDelete_mobile_es3 from '../../resources/images/atoto/userDelete_mobile_es3.png';
import userDelete_mobile_es4 from '../../resources/images/atoto/userDelete_mobile_es4.png';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    img: {
        maxWidth: '100%',
    }
}));

const UserDeletePage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const phone = useMediaQuery(theme.breakpoints.down('md'));

    if (phone) return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            Guía de cancelación de cuentas para el sitio web móvil

            </Typography>

            <Typography variant="h6" gutterBottom>
            Nota:
            </Typography>
            <Typography variant="body2" gutterBottom>
            Para desactivar o cancelar su cuenta de TrackHu Manager, visite el sitio web oficial de ATOTO como Gerente de TrackHU y ATOTO use la misma cuenta para iniciar sesión.

            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Inicie sesión en el sitio web oficial de ATOTO (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ) utilizando una cuenta registrada en el administrador de TrackHu.
 
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_es1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_es2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. Inicie sesión y haga clic en "Ver más" {">"} "Borrar mi cuenta" {">"} "Enviar" para cancelar la cuenta. (Ver ①② en las imágenes)

            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_es3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_es4} className={classes.img} />
            </Typography>
        </Container>
    )

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            Guía de cancelación de cuentas para el sitio web de PC
            </Typography>

            <Typography variant="h6" gutterBottom>
            Nota:
            </Typography>
            <Typography variant="body2" gutterBottom>
            Para desactivar o cancelar su cuenta de TrackHu Manager, visite el sitio web oficial de ATOTO como Gerente de TrackHU y ATOTO use la misma cuenta para iniciar sesión.
            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Inicie sesión en el sitio web oficial de ATOTO (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ) utilizando una cuenta registrada en el administrador de TrackHu.

            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_es1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_es2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. Inicie sesión y haga clic en "Ver más" {">"} "Borrar mi cuenta" {">"} "Enviar" para cancelar la cuenta. (Ver ①② en las imágenes)
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_es3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_es4} className={classes.img} />
            </Typography>
        </Container>
    );
};

export default UserDeletePage;
