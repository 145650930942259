import React, { useState } from 'react';
import {
    Container, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import userDelete_ja1 from '../../resources/images/atoto/userDelete_ja1.png';
import userDelete_ja2 from '../../resources/images/atoto/userDelete_ja2.png';
import userDelete_ja3 from '../../resources/images/atoto/userDelete_ja3.png';
import userDelete_ja4 from '../../resources/images/atoto/userDelete_ja4.png';
import userDelete_mobile_ja1 from '../../resources/images/atoto/userDelete_mobile_ja1.png';
import userDelete_mobile_ja2 from '../../resources/images/atoto/userDelete_mobile_ja2.png';
import userDelete_mobile_ja3 from '../../resources/images/atoto/userDelete_mobile_ja3.png';
import userDelete_mobile_ja4 from '../../resources/images/atoto/userDelete_mobile_ja4.png';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    img: {
        maxWidth: '100%',
    }
}));

const UserDeletePage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const phone = useMediaQuery(theme.breakpoints.down('md'));

    if (phone) return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            モバイルWebサイトのアカウントキャンセルガイド

            </Typography>

            <Typography variant="h6" gutterBottom>
            ノート:
            </Typography>
            <Typography variant="body2" gutterBottom>
            TrackHuマネージャーアカウントを非アクティブ化またはキャンセルするには、TrackHuマネージャーとATOTOの両方がログインに同じアカウントを使用するため、ATOTOの公式Webサイトにアクセスしてください。
            </Typography>

            <Typography variant="h6" gutterBottom>
                1. TrackHuマネージャーに登録されたアカウントを使用して、ATOTOの公式Webサイト (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ) にログインします。 
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_ja1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_ja2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
            2. ログインして“もっと” {">"} “アカウントを削除します” {">"} “送信”をクリックしてアカウントをキャンセルします。 （写真①②を参照）
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_ja3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_ja4} className={classes.img} />
            </Typography>
        </Container>
    )

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            PC Webサイトのアカウントキャンセルガイド

            </Typography>

            <Typography variant="h6" gutterBottom>
            ノート:
            </Typography>
            <Typography variant="body2" gutterBottom>
            TrackHuマネージャーアカウントを非アクティブ化またはキャンセルするには、TrackHuマネージャーとATOTOの両方がログインに同じアカウントを使用するため、ATOTOの公式Webサイトにアクセスしてください。
            </Typography>

            <Typography variant="h6" gutterBottom>
                1. TrackHuマネージャーに登録されたアカウントを使用して、ATOTOの公式Webサイト (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ) にログインします。
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_ja1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_ja2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. ログインして“もっと” {">"} “アカウントを削除します” {">"} “送信”をクリックしてアカウントをキャンセルします。 （写真①②を参照）
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_ja3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_ja4} className={classes.img} />
            </Typography>
        </Container>
    );
};

export default UserDeletePage;
