import React, { useState } from 'react';
import {
    Container, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import userDelete_ru1 from '../../resources/images/atoto/userDelete_ru1.png';
import userDelete_ru2 from '../../resources/images/atoto/userDelete_ru2.png';
import userDelete_ru3 from '../../resources/images/atoto/userDelete_ru3.png';
import userDelete_ru4 from '../../resources/images/atoto/userDelete_ru4.png';
import userDelete_mobile_ru1 from '../../resources/images/atoto/userDelete_mobile_ru1.png';
import userDelete_mobile_ru2 from '../../resources/images/atoto/userDelete_mobile_ru2.png';
import userDelete_mobile_ru3 from '../../resources/images/atoto/userDelete_mobile_ru3.png';
import userDelete_mobile_ru4 from '../../resources/images/atoto/userDelete_mobile_ru4.png';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    img: {
        maxWidth: '100%',
    }
}));

const UserDeletePage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const phone = useMediaQuery(theme.breakpoints.down('md'));

    if (phone) return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            Руководство по отмене учетной записи для мобильного сайта

            </Typography>

            <Typography variant="h6" gutterBottom>
            Примечание:
            </Typography>
            <Typography variant="body2" gutterBottom>
            Чтобы отключить или отменить свою учетную запись Manager TrackHu, пожалуйста, посетите официальный веб -сайт ATOTO, поскольку и Manager Trackhu, и ATOTO используют одну и ту же учетную запись для входа в систему.

            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Войдите на официальный веб -сайт ATOTO (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ), используя учетную запись, зарегистрированную в Trackhu Manager.
 
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_ru1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_ru2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. Войдите в систему и нажмите «Узнать больше» {">"} »Удалите мой аккаунт» {">"} «Представлять на рассмотрение», чтобы отменить учетную запись. (См. ①② на картинках)

            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_ru3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_ru4} className={classes.img} />
            </Typography>
        </Container>
    )

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            Руководство по отмене учетной записи для веб -сайта ПК

            </Typography>

            <Typography variant="h6" gutterBottom>
            Примечание:
            </Typography>
            <Typography variant="body2" gutterBottom>
            Чтобы отключить или отменить свою учетную запись Manager TrackHu, пожалуйста, посетите официальный веб -сайт ATOTO, поскольку и Manager Trackhu, и ATOTO используют одну и ту же учетную запись для входа в систему.

            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Войдите на официальный веб -сайт ATOTO (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ), используя учетную запись, зарегистрированную в Trackhu Manager.

            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_ru1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_ru2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. Войдите в систему и нажмите «Узнать больше» {">"} »Удалите мой аккаунт» {">"} «Представлять на рассмотрение», чтобы отменить учетную запись. (См. ①② на картинках)

            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_ru3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_ru4} className={classes.img} />
            </Typography>
        </Container>
    );
};

export default UserDeletePage;
