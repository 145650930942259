import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Snackbar } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffectAsync } from "../reactHelper";
import useQuery from "../common/util/useQuery";
import fetchFunc from "../fetchFunc";
import moment from "moment";
import {
  nativeEnvironment,
  nativePostMessage,
} from "../common/components/NativeInterface";
import { useDispatch, useSelector } from "react-redux";
import { sessionActions } from "../store";

const ThirdPartLoginPage = () => {
  // 浏览器中的 URL 参数
  const query = useQuery();

  // 错误提示
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const atotoErrorMessages = useSelector(
    (state) => state.atotoErrorMessages.messages
  );

  // 第三方登录
  const thirdPartLogin = async (authorizationCode, thirdPartPlatformType) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL_ATOTO}/atoto-user/thirdPartLogin/loginAtoto`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          authorizationCode,
          thirdPartPlatformType,
          // redirectDomainType: 回调地址域名类型，和后端约定的常量，1.资源中心 2.独立站 3.美国站 4.trccar
          redirectDomainType: 4,
        }),
      }
    );

    if (!response.ok) {
      throw Error(await response.text());
    }
    const res = await response.json();
    if (res.code !== 200) {
      if (`${res.code}`.startsWith("600")) {
        setErrorMessage(atotoErrorMessages[res.code] || res.message);
        setErrorSnackbarOpen(true);
      } else {
        setErrorMessage(res.message);
        setErrorSnackbarOpen(true);
      }
      return;
    }
    const { email, nickName, tokenHead, token } = res.data;
    // 保存token
    window.localStorage.setItem("atotoToken", tokenHead + token);
    // 商城登录成功再请求获取trccar登录密码
    await getTrccarPassword(email, nickName);
  };

  /**
   * 获取trccar用户的登录密码，是商城用户，但是不是trccar用户，则后台去注册trccar并与商城用户绑定
   */
  const getTrccarPassword = async (email, nickName) => {
    const response = await fetch(
      `${process.env.REACT_APP_URL_ATOTO}/atoto-gps-core/gps/customer/checkOrCreateAUserInTraccar`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ name: nickName, email }),
      }
    );

    if (!response.ok) throw Error(await response.text());
    const res = await response.json();
    if (res.code !== 200) {
      if (`${res.code}`.startsWith("600")) {
        setErrorMessage(atotoErrorMessages[res.code] || res.message);
        setErrorSnackbarOpen(true);
      } else {
        setErrorMessage(res.message);
        setErrorSnackbarOpen(true);
      }
      return;
    }

    const { password } = res.data;
    //成功后登录
    loginTrccar(email, password);
  };

  /**
   * 登录trccar
   * @param {*} email 用户邮箱
   * @param {*} password 密码
   * @param {*} goRegister 注册的方法
   * @param {*} nickName 用户名
   */
  const loginTrccar = async (email, password) => {
    try {
      const response = await fetchFunc("/api/session", {
        method: "POST",
        // headers: {
        //   Authorization: window.localStorage.getItem("atotoToken") || "",
        // },
        body: new URLSearchParams(
          `email=${encodeURIComponent(email)}&password=${encodeURIComponent(
            password
          )}&${undefined}=${false}`
        ),
      });
      if (response.ok) {
        const auth = "Basic " + btoa(encodeURI(`${email}:${password}`));
        // window.sessionStorage.setItem('basicAuth', auth);
        const user = await response.json();
        generateLoginToken();
        dispatch(sessionActions.updateUser(user));
        navigate("/");
      } else {
        throw Error(await response.text());
      }
    } catch (error) {
      throw Error(error);
    }
  };

  const generateLoginToken = async () => {
    if (nativeEnvironment) {
      let token = "";
      try {
        const expiration = moment().add(6, "months").toISOString();
        const response = await fetchFunc("/api/session/token", {
          method: "POST",
          body: new URLSearchParams(`expiration=${expiration}`),
        });
        if (response.ok) {
          token = await response.text();
        }
      } catch (error) {
        token = "";
      }
      nativePostMessage(`login|${token}`);
    }
  };

  useEffectAsync(async () => {
    const code = query.get("code");
    const state = query.get("state");
    if (code && state) {
      const urlParams = new URLSearchParams(state);
      const thirdPartPlatformType = parseInt(
        urlParams.get("thirdPartPlatformType") || ""
      );
      await thirdPartLogin(code, thirdPartPlatformType);
    }
    return null;
  }, [query]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
      <Snackbar
        open={errorSnackbarOpen}
        onClose={() => setErrorSnackbarOpen(false)}
        message={errorMessage}
        autoHideDuration={3000}
      />
    </>
  );
};

export default ThirdPartLoginPage;
