import React, { useState } from 'react';

// useForm hook to handle form state and validation
export default () => {
    const [formData, setFormData] = useState({});
    const [errors, setErrors] = useState({});
    const [validates, setValidates] = useState({});

    const register = (name, { defaultValue, validate }) => {
        if (!name) throw new Error('name is required');
        if (formData[name] === undefined) setFormData((prev) => ({ ...prev, [name]: defaultValue || '' }))
        if (errors[name] === undefined) setErrors((prev) => ({ ...prev, [name]: '' }));
        if (validate && validates[name] === undefined) setValidates((prev) => ({ ...prev, [name]: validate }))

        return {
            value: formData[name] || '',
            error: !!errors[name],
            onChange: (event) => {
                const { value } = event.target;
                setFormData((prev) => ({ ...prev, [name]: value }));
                validate && setErrors((prev) => ({ ...prev, [name]: validate(value) }));
            },
            onBlur: () => {
                validate && setErrors((prev) => ({ ...prev, [name]: validate(formData[name]) }));
            },
        };
    }

    const unregister = (name) => {
        if (!name) throw new Error('name is required');
        setFormData((prev) => {
            const { [name]: _, ...rest } = prev;
            return rest;
        });
        setErrors((prev) => {
            const { [name]: _, ...rest } = prev;
            return rest;
        });
        setValidates((prev) => {
            const { [name]: _, ...rest } = prev;
            return rest;
        });
    }

    const handleSubmit = (callback) => {
        if (!callback) throw new Error('callback is required');
        const newErrors = Object.keys(validates).reduce((acc, name) => {
            if (validates[name]) {
                acc[name] = validates[name](formData[name]);
            }
            return acc;
        }, {});

        setErrors(newErrors);

        if (Object.values(newErrors).every((error) => !error)) {
            callback(formData);
        }
    }

    const updataFormData = (newFormData, validate = true) => {
        if(!newFormData) throw new Error('newFormData is required');
        setFormData((prev) => ({ ...prev, ...newFormData }));
        validate && Object.keys(newFormData).forEach((name) => {
            if (validates[name]) {
                setErrors((prev) => ({ ...prev, [name]: validates[name](newFormData[name]) }));
            }
        })
    }

    return ({ register, handleSubmit, formState: { formData, errors }, updataFormData, unregister })
}