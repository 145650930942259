import React from 'react';
import {
    Popover, Button, TextField, FormControl, Grid, FormLabel
} from '@mui/material';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import { makeStyles } from '@mui/styles';
import { useTranslation } from './LocalizationProvider';
import { useSelector } from 'react-redux';
import { validEmail } from '../util/stringUtils'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'fixed',
        bottom: theme.spacing(11),
        right: theme.spacing(1),
    },
    openBtn: {
        minWidth: theme.spacing(5),
        width: theme.spacing(5),
        height: theme.spacing(5),
        borderRadius: '50%',
        backgroundColor: theme.palette.background.default,
        boxShadow: theme.shadows[2],
    },
    icon: {
        color: theme.palette.primary.main,
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        width: theme.spacing(32),
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.default,
        gap: theme.spacing(2),
    },
    formOption: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    successTitle: {
        textAlign: 'center',
    },
    successIcon: {
        color: theme.palette.success.main,
        verticalAlign: '-5px',
    }
}))

const FaqDialog = () => {
    const user = useSelector((state) => state.session.user);
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [formData, setFormData] = React.useState({ email: user?.email || '', content: '' });
    const [formValid, setFormValid] = React.useState({ email: true });
    const [showSuccess, setShowSuccess] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const open = Boolean(anchorEl);

    const t = useTranslation();

    const handleClick = (event) => {
        setShowSuccess(false)
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setFormData({ email: '', content: '' });
    };

    const handleFormChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const validEmailFormat = () => {
        setFormValid({ ...formValid, email: validEmail(formData.email) });
    }

    const validFormRequired = () => Object.values(formData).some(item => !item) || !validEmail(formData.email)

    const send = async () => {
        if (validFormRequired()) return
        setLoading(true)
        const response = await fetch(`${process.env.REACT_APP_URL_ATOTO}/atoto-erp-core/mobile/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...formData, source: 5 }),
        })

        if (response.ok) {
            const res = await response.json()
            console.log(res)
            if (res.code === 200) {
                setShowSuccess(true)
                form.resetFields()
            }
        }
        setLoading(false)
    }

    const renderSuccess = () => {
        return (
            <>
                <div className={classes.successTitle}>
                    <CheckCircleOutlineOutlinedIcon className={classes.successIcon} />
                    {t("shareSubmitSuccess")}
                </div>
                    <Button variant="outlined" onClick={handleClose}>
                        {t('shareOk')}
                    </Button>
            </>
        )
    }

    const renderform = () => <>
        <TextField
            required
            error={!formValid.email}
            name='email'
            label={t('userEmail')}
            value={formData.email}
            onChange={handleFormChange}
            helperText={!formValid.email && t('loginEmailTips')}
            onBlur={validEmailFormat}
        />
        <FormLabel sx={{fontSize: 12}}>{t('feedbackEmailTips')}</FormLabel>

        <TextField
            required
            label={t('feedbackMessage')}
            multiline
            rows={4}
            name='content'
            onChange={handleFormChange}
        />
        <FormControl className={classes.formOption}>
            <Button color="primary" onClick={handleClose}>{t('sharedCancel')}</Button>
            <Button loading={loading} disabled={validFormRequired()} variant="contained" color="primary" onClick={send}>{t('commandSend')}</Button>
        </FormControl></>

    return (
        <div className={classes.root}>
            <Button className={classes.openBtn} onClick={handleClick}>
                <HeadsetMicOutlinedIcon className={classes.icon} />
            </Button>
            <Popover
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <div className={classes.content}>
                    {showSuccess ? renderSuccess() : renderform()}
                </div>
            </Popover>
        </div>
    );
}

export default FaqDialog;