import React, { useState } from 'react';
import {
    Container, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import userDelete1 from '../../resources/images/atoto/userDelete1.png';
import userDelete2 from '../../resources/images/atoto/userDelete2.png';
import userDelete3 from '../../resources/images/atoto/userDelete3.png';
import userDelete4 from '../../resources/images/atoto/userDelete4.png';
import userDelete_mobile1 from '../../resources/images/atoto/userDelete_mobile1.png';
import userDelete_mobile2 from '../../resources/images/atoto/userDelete_mobile2.png';
import userDelete_mobile3 from '../../resources/images/atoto/userDelete_mobile3.png';
import userDelete_mobile4 from '../../resources/images/atoto/userDelete_mobile4.png';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    img: {
        maxWidth: '100%',
    }
}));

const UserDeletePage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const phone = useMediaQuery(theme.breakpoints.down('md'));

    if (phone) return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            Account Cancellation Guide for Mobile Website
            </Typography>

            <Typography variant="h6" gutterBottom>
                Note:
            </Typography>
            <Typography variant="body2" gutterBottom>
                To deactivate or cancel your TrackHU Manager account,
                please visit the ATOTO official website as both TrackHU Manager and ATOTO use the same account for login.
            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Log into the ATOTO official website (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ) using an account registered on the TrackHU APP. 
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. Log in and click "More" {">"} "Delete My Account" {">"} "Submit" to cancel the account. (See ①② in the pictures)
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile4} className={classes.img} />
            </Typography>
        </Container>
    )

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
                Account Cancellation Guide for PC Website
            </Typography>

            <Typography variant="h6" gutterBottom>
                Note:
            </Typography>
            <Typography variant="body2" gutterBottom>
                To deactivate or cancel your TrackHU Manager account,
                please visit the ATOTO official website as both TrackHU Manager and ATOTO use the same account for login.
            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Log into the ATOTO official website (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ) using an account registered on the TrackHU Manager.
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. Log in and click "More" {">"} "Delete My Account" {">"} "Submit" to cancel the account. (See ①② in the pictures)
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete4} className={classes.img} />
            </Typography>
        </Container>
    );
};

export default UserDeletePage;
