import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
  name: 'atotoErrorMessages',
  initialState: {
    messages: {},
  },
  reducers: {
    set(state, action) {
      state.messages = action.payload;
    },
  },
});

export { actions as atotoErrorMessagesActions };
export { reducer as atotoErrorMessagesReducer };