import React, { useState } from 'react';
import {
    Container, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import userDelete_it1 from '../../resources/images/atoto/userDelete_it1.png';
import userDelete_it2 from '../../resources/images/atoto/userDelete_it2.png';
import userDelete_it3 from '../../resources/images/atoto/userDelete_it3.png';
import userDelete_it4 from '../../resources/images/atoto/userDelete_it4.png';
import userDelete_mobile_it1 from '../../resources/images/atoto/userDelete_mobile_it1.png';
import userDelete_mobile_it2 from '../../resources/images/atoto/userDelete_mobile_it2.png';
import userDelete_mobile_it3 from '../../resources/images/atoto/userDelete_mobile_it3.png';
import userDelete_mobile_it4 from '../../resources/images/atoto/userDelete_mobile_it4.png';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    img: {
        maxWidth: '100%',
    }
}));

const UserDeletePage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const phone = useMediaQuery(theme.breakpoints.down('md'));

    if (phone) return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            Guida alla cancellazione dell'account per il sito Web mobile
            </Typography>

            <Typography variant="h6" gutterBottom>
            Nota:
            </Typography>
            <Typography variant="body2" gutterBottom>
            Per disattivare o annullare il tuo account TrackHu Manager, visitare il sito Web ufficiale di ATOTO come manager trackhu e ATOTO utilizzare lo stesso account per l'accesso.

            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Accedi al sito Web ufficiale di ATOTO (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ) utilizzando un account registrato sul gestore trackhu.
 
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_it1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_it2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. Accedi e fai clic su "Vedi altro" {">"} "Cancella il mio account" {">"} "Invia" per annullare l'account. (Vedi ①② nelle immagini)
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_it3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_it4} className={classes.img} />
            </Typography>
        </Container>
    )

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            Guida alla cancellazione dell'account per il sito Web PC
            </Typography>

            <Typography variant="h6" gutterBottom>
            Nota:
            </Typography>
            <Typography variant="body2" gutterBottom>
            Per disattivare o annullare il tuo account TrackHu Manager, visitare il sito Web ufficiale di ATOTO come manager trackhu e ATOTO utilizzare lo stesso account per l'accesso.

            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Accedi al sito Web ufficiale di ATOTO (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ) utilizzando un account registrato sul gestore trackhu.
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_it1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_it2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. Accedi e fai clic su "Vedi altro" {">"} "Cancella il mio account" {">"} "Invia" per annullare l'account. (Vedi ①② nelle immagini)
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_it3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_it4} className={classes.img} />
            </Typography>
        </Container>
    );
};

export default UserDeletePage;
