import React, { useState } from 'react';
import {
    Container, Card, Typography, List, ListItem, ListItemText, Divider,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PageLayout from '../common/components/PageLayout';
import SettingsMenu from './components/SettingsMenu';
import Logo from '../resources/images/atoto/logo.png';
import { useTranslation } from '../common/components/LocalizationProvider';
import { useNavigate } from 'react-router-dom';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import IconButton from '@mui/material/IconButton';

const useStyles = makeStyles((theme) => ({
    container: {
        marginTop: theme.spacing(2),
        height: '100%',
    },
    card: {
        height: 'calc(100% - 16px)',
    },
    logo: {
        width: theme.spacing(20),
        margin: theme.spacing(2),
    },
    list: {
        marginTop: theme.spacing(4),
    },
    icon: {
        marginTop: theme.spacing(0.5),
    }
}));

const AboutUsPage = () => {
    const classes = useStyles();
    const t = useTranslation();
    const navigate = useNavigate();

    return (
        <PageLayout menu={<SettingsMenu />} breadcrumbs={['settingsTitle', 'aboutUs']}>
            <Container maxWidth="xs" className={classes.container}>
                <Card sx={{ minWidth: 320 }} className={classes.card}>
                    <Typography align='center' variant="body2">
                        <img className={classes.logo} src={Logo} />
                    </Typography>
                    <Typography align='center' variant="h6" gutterBottom>
                        {t('appName')}
                    </Typography>

                    <List className={classes.list} component="nav" aria-label="mailbox folders">
                        <Divider />
                        <ListItem
                            button
                            alignItems='center'
                            secondaryAction={<ChevronRightOutlinedIcon className={classes.icon} />}
                            onClick={() => navigate('/privacy-policy')}
                        >
                            <ListItemText primary={t('loginPrivacyPolicy')} />
                        </ListItem>
                        <Divider />
                        <ListItem
                            button
                            divider
                            secondaryAction={<ChevronRightOutlinedIcon className={classes.icon} />}
                            onClick={() => navigate('/terms-use')}
                        >
                            <ListItemText primary={t('loginTermsUse')} />
                        </ListItem>
                    </List>
                </Card>
            </Container>
        </PageLayout>
    );
};

export default AboutUsPage;
