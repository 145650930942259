export default function fetchFunc(url,options={headers:{}}) {
    url = `${process.env.REACT_APP_URL_NAME}${url}`
    // const auth = window.sessionStorage.getItem('basicAuth')
    // if(auth) {
    //     options.headers={
    //         ...options.headers,
    //         Authorization: window.sessionStorage.getItem('basicAuth') || ''
    //     }
    // }
    return fetch(url,options)
}

export async function atotoRequest(url,options){
    const response = await fetch(`${process.env.REACT_APP_URL_ATOTO}${url}`, options)
    if (response.ok) {
        const res = await response.json();
        return {
            ...res,
            success: res.code===200
        }
    } else {
        throw Error(await response.text());
    }
}