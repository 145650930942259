import React from 'react';
import { makeStyles } from '@mui/styles';
import { ReactComponent as Logo } from '../resources/images/logo.svg';
import { fontSize } from '@mui/system';
import { useTranslation } from '../common/components/LocalizationProvider';
import LogoImg from '../resources/images/atoto/logo.png';

// const useStyles = makeStyles(() => ({
//   image: {
//     alignSelf: 'center',
//     maxWidth: '240px',
//     maxHeight: '120px',
//     width: 'auto',
//     height: 'auto',
//   },
// }));

const useStyles = makeStyles(() => ({
  div: {
    alignSelf: 'center',
    maxWidth: '260px',
    // maxHeight: '120px',
    width: 'auto',
    height: 'auto',
    color: '#d52121',
    fontSize: '30px',
    fontWeight: '500'
  },
  img:{
    display: 'block',
    margin: '0 auto',
  }
}));

const LogoImage = ({ color }) => {
  const classes = useStyles();
  const t = useTranslation();

  // return (<Logo className={classes.image} style={{ color }} />);
  return <div className={classes.div}>
    <img className={classes.img} width={80} src={LogoImg} />
    {t('appName')}
    </div>
};

export default LogoImage;
