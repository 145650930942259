import React, { useState } from 'react';
import {
  Button, TextField, Typography, Snackbar, IconButton, FormControl, InputAdornment, InputLabel, OutlinedInput, FormHelperText, FormLabel, Link
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LoginLayout from './LoginLayout';
import { useTranslation } from '../common/components/LocalizationProvider';
import { snackBarDurationShortMs } from '../common/util/duration';
import { useCatch } from '../reactHelper';
import fetchFunc from '../fetchFunc';
import { useLocalization } from '../common/components/LocalizationProvider';
import { validEmail, validPassword } from '../common/util/stringUtils';
import CarInfo from './CarInfo'
import { useSelector } from 'react-redux';

export const langMap = {
  en: 'EN',
  ja: 'JP',
  de: 'DE',
  fr: 'FR',
  it: 'IT',
  es: 'ES',
  zh: 'ZH',
  ru: 'RU',
  ar: 'AR',
  pt: 'PT',
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  header: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginLeft: theme.spacing(1),
    textTransform: 'uppercase',
  },
  username: {
    display: 'flex',
    flexDirection: 'row',
  }
}));

const RegisterPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const t = useTranslation();
  const { language } = useLocalization();
  const atotoErrorMessages = useSelector((state) => state.atotoErrorMessages.messages);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [errorSnackbarOpen, setErrorSnackbarOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showCarInfo, setShowCarInfo] = useState(false);
  const [formValid, setFormValid] = React.useState({ email: true, password: true, password2: true });
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const validateForm = () => {
    const valid = {
      email: validEmail(email),
      password: validPassword(password),
      password2: password === password2,
    };
    setFormValid(valid);
    return Object.values(valid).every((v) => v);
  }

  const handleNext = () => {
    if (validateForm()) {
      setShowCarInfo(true);
    }
  }

  const handleSubmit = useCatch(async (carInfo) => {
    // const response = await fetchFunc('/api/users', {
    //   method: 'POST',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify({ name, email, password }),
    // });
    // if (response.ok) {
    //   setSnackbarOpen(true);
    // } else {
    //   throw Error(await response.text());
    // }
    setLoading(true)
    const params = {
      ...carInfo,
      email,
      password,
      source: 16,
      password2,
      firstName: firstName ? firstName : undefined,
      lastName: lastName ? lastName : undefined,
    }
    const response = await fetch(`${process.env.REACT_APP_URL_ATOTO}/atoto-user/customer/registry/sendEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Userinfo': encodeURIComponent(JSON.stringify({ lang: langMap[language] || "EN" }))
      },
      body: JSON.stringify(params),
    });
    if (response.ok) {
      const res = await response.json();
      if (res.code === 200) {
        setSnackbarOpen(true);
        // setName('')
        setEmail('')
        setPassword('')
      } else if (`${res.code}`.startsWith('600')) {
        setErrorMessage(atotoErrorMessages[res.code] || res.message)
        setErrorSnackbarOpen(true)
      }
    } else {
      throw Error(await response.text());
    }
    setLoading(false)
  });

  if (showCarInfo) return (
    <LoginLayout>
      <div className={classes.container}>
        <CarInfo loading={loading} onSubmit={handleSubmit} onCancle={() => setShowCarInfo(false)} />
      </div>
      <Snackbar
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false)
          // navigate('/login')
          navigate(-1)
        }}
        autoHideDuration={3000}
        message={t('registerSendEmail')}
      />
      <Snackbar
        open={errorSnackbarOpen}
        onClose={() => setErrorSnackbarOpen(false)}
        message={errorMessage}
        autoHideDuration={3000}
      />
    </LoginLayout>
  )

  return (
    <LoginLayout>
      <div className={classes.container}>
        <div className={classes.header}>
          <IconButton color="primary" onClick={() => navigate(-1)}>
            <ArrowBackIcon />
          </IconButton>
          <Typography className={classes.title} color="primary">
            {t('loginRegister')}
          </Typography>
        </div>
        {/* <TextField
          required
          label={t('sharedName')}
          name="name"
          value={name}
          autoComplete="name"
          autoFocus
          onChange={(event) => setName(event.target.value)}
        /> */}
        <TextField
          required
          error={!formValid.email}
          type="email"
          label={t('userEmail')}
          name="email"
          value={email}
          autoComplete="email"
          onChange={(event) => {
            setEmail(event.target.value)
            setFormValid({ ...formValid, email: validEmail(event.target.value) })
          }}
          helperText={!formValid.email && t('loginEmailTips')}
          onBlur={() => setFormValid({ ...formValid, email: validEmail(email) })}
        />
        <FormControl variant="outlined">
          <InputLabel htmlFor="user-password">{t('userPassword')}</InputLabel>
          <OutlinedInput
            id="user-password"
            type={showPassword ? 'text' : 'password'}
            required
            error={!formValid.password}
            label={t('userPassword')}
            name="password"
            value={password}
            autoComplete="current-password"
            onChange={(event) => {
              setPassword(event.target.value)
              setFormValid({ ...formValid, password: validPassword(event.target.value) })
            }}
            onBlur={() => setFormValid({ ...formValid, password: validPassword(password) })}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {!formValid.password ? <FormHelperText error>{t('loginPasswordTips')}</FormHelperText>: null}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="user-password">{t('userPassword')}</InputLabel>
          <OutlinedInput
            required
            error={!formValid.password2}
            label={t('loginPasswordAgain')}
            name="password2"
            value={password2}
            type={showPassword ? 'text' : 'password'}
            autoComplete="current-password"
            onChange={(event) => {
              setPassword2(event.target.value)
              setFormValid({ ...formValid, password2: password === event.target.value })
            }}
            onBlur={() => setFormValid({ ...formValid, password2: password === password2 })}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {!formValid.password2 ? <FormHelperText error>{t('loginPasswordConfirmTips')}</FormHelperText>: null}
        </FormControl>
        <FormControl className={classes.username}>
          <TextField
            required
            label={t('loginFirstName')}
            name="firstName"
            value={firstName}
            autoComplete="current-password"
            onChange={(event) => setFirstName(event.target.value)}
          />
          <TextField
            required
            label={t('loginLastName')}
            name="lastName"
            value={lastName}
            autoComplete="current-password"
            onChange={(event) => setLastName(event.target.value)}
          />
        </FormControl>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleNext}
          fullWidth
        >
          {t('shareSubmit')}
        </Button>
        <FormLabel>
          {t('loginPrivacyClick')}{' '}
          <Link href="/#/privacy-policy" target="_blank" rel="noopener">
            {t('loginPrivacyPolicy')}
          </Link>
          {' '}{t('loginAnd')}{' '}
          <Link href="/#/terms-use" target="_blank" rel="noopener">
            {t('loginTermsUse')}
          </Link>
        </FormLabel>
      </div>
      <Snackbar
        open={snackbarOpen}
        onClose={() => {
          setSnackbarOpen(false)
          setShowCarInfo(false)
          // navigate('/login')
          navigate(-1)
        }}
        autoHideDuration={3000}
        message={t('registerSendEmail')}
      />
      <Snackbar
        open={errorSnackbarOpen}
        onClose={() => setErrorSnackbarOpen(false)}
        message={errorMessage}
        autoHideDuration={3000}
      />
    </LoginLayout>
  );
};

export default RegisterPage;
