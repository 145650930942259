import React from "react";
import Button from "@mui/material/Button";
import { Snackbar } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useTranslation } from "./LocalizationProvider";
import { useCatch } from "../../reactHelper";
import { snackBarDurationLongMs } from "../util/duration";
import fetchFunc from "../../fetchFunc";

const useStyles = makeStyles((theme) => ({
  button: {
    height: "auto",
    marginTop: 0,
    marginBottom: 0,
    color: theme.palette.colors.negative,
  },
}));

const RemoveDialog = ({ open, endpoint, itemId, onResult }) => {
  const classes = useStyles();
  const t = useTranslation();

  const handleRemove = useCatch(async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_URL_ATOTO}/atoto-gps-core/gps/customer/unbind`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ uniqueId: itemId }),
        }
      );
      if (response.ok) {
        onResult(true);
      }
    } catch (error) {
      console.log(error);
    }
  });

  return (
    <Snackbar
      open={open}
      autoHideDuration={snackBarDurationLongMs}
      onClose={() => onResult(false)}
      message={t("sharedRemoveConfirm")}
      action={
        <Button size="small" className={classes.button} onClick={handleRemove}>
          {t("sharedRemove")}
        </Button>
      }
    />
  );
};

export default RemoveDialog;
