import fetchFunc from "../../fetchFunc";

export default async function getEventTypes() {
  const showEventTypes = ['deviceUnknown', 'geofenceExit', 'geofenceEnter', 'deviceOnline']
  const response = await fetchFunc('/api/notifications/types');
  if (response.ok) {
    let types = await response.json() || [];
    return types.filter(item => showEventTypes.includes(item.type))
  } else {
    throw Error(await response.text());
  }
}