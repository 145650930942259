import React, { useState } from 'react';
import {
    Container, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';


const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        "& .MuiTypography-gutterBottom": {
            marginBottom: theme.spacing(2),
        }
    },
}));

const UserDeletePage = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h6" align='center' gutterBottom>
                Terms of Use
            </Typography>
            <Typography variant="body2" gutterBottom>
                Last Updated: 28 April 2023
            </Typography>
            <Typography variant="body2" gutterBottom>
                THIS TERMS OF USE AGREEMENT (THE “AGREEMENT”) ESTABLISHES THE TERMS AND CONDITIONS THAT APPLY TO YOU WHEN YOU USE THE SERVICE (AS DEFINED BELOW). BY USING THE SERVICE,
                YOU INDICATE YOUR ACCEPTANCE OF THIS AGREEMENT AND YOUR AGREEMENT TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS AGREEMENT, AS WELL AS ALL APPLICABLE LAWS AND REGULATIONS.
                YOU ARE NOT PERMITTED TO USE THE SERVICE IF YOU DO NOT AGREE TO ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT. THIS AGREEMENT CAN BE CHANGED, MODIFIED, SUPPLEMENTED,
                AND/OR UPDATED BY AOTULE ELECTRONICS, INC. (“ATOTO”, “THE COMPANY”, “WE”, “OUR”,” OR “US”) AT ANY TIME; PROVIDED THAT WE WILL PUBLISH PRIOR NOTICE OF ANY MATERIAL CHANGES.
                YOUR CONTINUED USE OF THE SERVICE AFTER THE MODIFICATION OF THIS AGREEMENT MEANS THAT YOU ACCEPT ALL SUCH CHANGES. ACCORDINGLY, YOU ARE ADVISED TO CONSULT THIS AGREEMENT EACH
                TIME YOU ACCESS THE SERVICE IN ORDER TO VIEW ANY CHANGES TO THIS AGREEMENT. THIS AGREEMENT WAS LAST MODIFIED AS OF THE DATE INDICATED ABOVE.
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. The “Service” is ATOTO TrackHU’s Android applications for in-car systems “TrackHU”, mobile applications “TrackHU Manager” and website located at
                https://gpstrack.myatoto.com, as each may be updated, relocated, or otherwise modified from time to time, including through networks, embeddable widgets,
                downloadable software, and tablet computer applications, and all intellectual property contained therein. The Service provides a GPS-GSM service allowing you to find and track the
                device with a network (the “ATOTO TrackHU Network”) through which ATOTO can provide locations (each, a “Location”) to consumers. Any person who accesses and/or uses the ATOTO TrackHU Network
                to locate a device on his or her own behalf, or on behalf of any third party, will be referred to herein as a (“ATOTO TrackHU Member”).
            </Typography>
            <Typography variant="body2" gutterBottom>
                b. Subject to the terms and conditions of this Agreement, THE COMPANY hereby grants you a limited revocable, non-exclusive, non-transferable license to access and use the Service,
                solely in the manner intended by THE COMPANY. Unless otherwise specified in writing, the Service is solely for your personal use and not for resale.
                THE COMPANY reserves the right at all times and without notice to: (I) restrict and/or terminate your access to the Service (or any portion thereof);
                and (II) modify or discontinue providing the Service (or any portion thereof).
            </Typography>
            <Typography variant="body2" gutterBottom>
                c. THE COMPANY’s policy with respect to the collection and use of your personally identifiable information is set in our Privacy Policy. By accepting this Agreement,
                you acknowledge your agreement with ATOTO TrackHU’s Privacy Policy.
            </Typography>
            <Typography variant="body2" gutterBottom>
                2. Registration, Accounts, Passwords and Security
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. ATOTO Members (Including members from ATOTO Mall and ATOTO TrackHU). In order to become a ATOTO Member, you must complete the registration process by providing THE COMPANY with current,
                complete and accurate information, as prompted by the applicable registration form.
            </Typography>
            <Typography variant="body2" gutterBottom>
                b. Accuracy of Information. You acknowledge that in the event you provide any information to THE COMPANY which is untrue, inaccurate, not current or incomplete,
                THE COMPANY may terminate this Agreement and your continued access and use of the Service.
            </Typography>
            <Typography variant="body2" gutterBottom>
                c. Eligibility. You represent and warrant that you are at least eighteen (18) years of age, that you have not been previously suspended or removed from the Service,
                and that you possess the legal right and ability to enter into this Agreement. If you are using the Service on behalf of an individual, company, entity, or organization,
                you represent and warrant that you are an authorized representative of such party with the authority to (I) bind such party to this Agreement, and (II) agree to be bound by the
                Agreement on behalf of such party. THE SERVICE IS NOT FOR PERSONS UNDER THE AGE OF 13 OR ANY USERS PREVIOUSLY SUSPENDED OR REMOVED FROM THE SERVICE BY THE COMPANY.
                IF YOU ARE UNDER 13 YEARS OF AGE, PLEASE DO NOT USE OR ACCESS THE SERVICE AT ANY TIME OR IN ANY MANNER. While individuals under the age of 18 may use the ATOTO TrackHU services
                through the Service by a parent or legal guardian (who must also supervise the ATOTO device), such individuals may not use the Service themselves.
            </Typography>
            <Typography variant="body2" gutterBottom>
                d. Credentials. As part of the registration process, you will be asked to select a username and password. You are entirely responsible for maintaining the security and
                confidentiality of your account’s identification details and password. You agree to notify THE COMPANY immediately of any unauthorized use of your account or any other breach of security.
                To notify us, contact us at Support. You are responsible for all use of the Service occurring under your user name. You are responsible for keeping your password confidential and for
                notifying us if your password has been hacked or stolen. THE COMPANY will not be liable for any loss that you may incur as a result of someone else using your password or account,
                either with or without your knowledge. You may be held liable for any losses incurred by THE COMPANY or another party due to someone else using your account or password.
            </Typography>
            <Typography variant="body2" gutterBottom>
                3. Your Responsibilities
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. You may use the Service solely for lawful, non-commercial purposes, as intended through the provided functionality of the Service. You may not use the Service in
                any manner that could damage, disable, overburden, or impair our servers or networks, or interfere with any other party’s use and enjoyment of the Service.
                You may not attempt to gain unauthorized access to the Service, user accounts, or computer systems or networks, through hacking, password mining or any other means. Without
                limiting any of the foregoing, you expressly agree that you will not (and you agree not to allow or assist any third party to):
            </Typography><Typography variant="body2" gutterBottom>
                I. use, copy, install, transfer or distribute the Service, except as specifically permitted by this Agreement.
            </Typography>
            <Typography variant="body2" gutterBottom>
                II. modify, adapt, translate, reverse engineer, decompile, or disassemble any portion of the Service or its Content (as defined below);
            </Typography>
            <Typography variant="body2" gutterBottom>
                III. remove or alter any copyright, trademark or other proprietary rights notices contained in or on the Service or in or on any content or other material obtained through the Service or the use of the Service;
            </Typography>
            <Typography variant="body2" gutterBottom>
                IV. create user accounts by automated means or under false or fraudulent pretenses.
            </Typography>
            <Typography variant="body2" gutterBottom>
                V. use any robot, spider, screen or database scraper, site search or retrieval application, or other automated device, process or means to access, retrieve or index any portion of the Service;
            </Typography>
            <Typography variant="body2" gutterBottom>
                VI. probe, scan or test the vulnerability of any system or network or breach any security or authentication measures;
            </Typography>
            <Typography variant="body2" gutterBottom>
                VII. reformat, mirror, or frame any portion of the web pages that are part of the Service;
            </Typography>
            <Typography variant="body2" gutterBottom>
                VIII. reformat, mirror, or frame any portion of the applications that are part of the Service.
            </Typography>
            <Typography variant="body2" gutterBottom>
                IX. transmit (A) any content or information that is unlawful, fraudulent, threatening, abusive, libelous, defamatory, obscene or otherwise objectionable,
                or infringes on our or any third party’s intellectual property or other rights, (B) any material, non- public information about individuals or companies without the authorization to do so,
                (C) any trade secret of any third party, and/or (D) any advertisements, solicitations, chain letters, pyramid schemes, investment opportunities, or other unsolicited commercial
                communication (except as otherwise expressly permitted by us), or engage in spamming or flooding;
            </Typography>
            <Typography variant="body2" gutterBottom>
                X. transmit any software or other materials that contain any virus, worm, time bomb, Trojan horse, or other harmful or disruptive component; use any robot, spider, site search/retrieval application,
                or other manual or automatic device or process to retrieve, index, “data mine”, or in any way reproduce or circumvent the navigational structure or presentation of the Service or its contents;
            </Typography>
            <Typography variant="body2" gutterBottom>
                XI. harvest or collect information about other users without their prior written consent;
            </Typography>
            <Typography variant="body2" gutterBottom>
                XII. undertake, cause, permit or authorize the translation, reverse engineering, disassembling or hacking of any aspect of the Service, including Content,
                or attempt to do any of the foregoing, except and solely to the extent permitted by this Agreement, the Service’s authorized features, or by law,
                or otherwise attempt to use or access any portion of the Service other than as intended by THE COMPANY;
            </Typography>
            <Typography variant="body2" gutterBottom>
                XIII. access, tamper with, or use non-public areas of the Service, THE COMPANY’s (and its hosting company’s) computer systems and infrastructure,
                or the technical delivery systems of THE COMPANY’s providers;
            </Typography>
            <Typography variant="body2" gutterBottom>
                XIV. harass, abuse, harm or advocate or incite harassment, abuse or harm of another person or group, including THE COMPANY employees and other users;
            </Typography>
            <Typography variant="body2" gutterBottom>
                XV. create a new account with ATOTO , without THE COMPANY’s express written consent, if THE COMPANY previously disabled an account of yours;
            </Typography>
            <Typography variant="body2" gutterBottom>
                XVI. solicit, or attempt to solicit, personal information from other users, except as permitted through the Service’s functionality;
            </Typography>
            <Typography variant="body2" gutterBottom>
                XVII. restrict, discourage or inhibit any person from using the Service, disclose personal information about a third person
                on the Service or obtained from the Service without the consent of such person, or collect information about users;
            </Typography>
            <Typography variant="body2" gutterBottom>
                XVIII. gain unauthorized access to the Service, to other users’ accounts, names or personally identifiable information, or to other computers or websites connected or linked to the Service.
            </Typography>
            <Typography variant="body2" gutterBottom>
                XIX. violate any applicable federal, state or local laws, regulations or this Agreement;
            </Typography>
            <Typography variant="body2" gutterBottom>
                XX. use the Service for any illegal, inappropriate and/or unauthorized conduct, including without limitation, using the Service in violation of THE
                COMPANY’s or any third party’s intellectual property or other proprietary or legal rights; or
            </Typography>
            <Typography variant="body2" gutterBottom>
                XXI. use or access the Service to build a competing service.We may take any legal action and implement any technical remedies to prevent the violation of these provisions and to enforce this Agreement.            </Typography>
            <Typography variant="body2" gutterBottom>
                4. Consent to Electronic Communications
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. Consent to Electronic Communications. By using the Service or providing Personal Information (as defined in the Privacy Policy) to us, you agree that we may communicate
                with you electronically regarding security, privacy, administrative issues relating to your use of the Service as well as marketing information about ATOTO products,
                promotions and apps. If we learn of a security system’s breach, we may attempt to notify you electronically by posting a notice on the Service or sending an email to you,
                if we have your email address. You may have a legal right to receive this notice in writing. To receive free written notice of a security breach (or to withdraw your consent
                from receiving electronic notice), please write to us at Support.
            </Typography>
            <Typography variant="body2" gutterBottom>
                b. Emails and Push Notifications. When you register with ATOTO , THE COMPANY may send you an email containing a code in order to verify your email and may send emails and
                push notifications (“Emails and Notifications”) in order to keep you informed about the Service, and may present to you certain marketing offers related to the service.
                By using the Service, you agree to receive Emails and Notifications regarding your use of the Service. Emails and Notifications are intended to enhance your use of the Service,
                you may disable emails and push notifications on your phone if you feel disturbed.
            </Typography>
            <Typography variant="body2" gutterBottom>
                5. Content Submitted to THE COMPANY
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. By sending or transmitting to us information, opinions, creative suggestions, ideas, notes, concepts, or other materials (collectively, “Materials”), or by posting such
                Materials to any area of the Service, you grant THE COMPANY and its designees a worldwide, non-exclusive, sublicenseable (through multiple tiers), assignable, royalty-free,
                perpetual, irrevocable right to use, reproduce, distribute (through multiple tiers), create derivative works of, publicly perform, publicly display, digitally perform, make, have made,
                sell, offer for sale and import such Materials in any media now known or hereafter developed to enhance and develop the Service, including by marketing and advertising the Service,
                without compensation to you; provided that we will never use your name in connection with any of your Materials that we use in our own advertising and marketing materials without obtaining
                your prior consent. None of the Materials will be subject to any obligation, whether of confidentiality, attribution, or otherwise, on our part and we will not be liable for any use or
                disclosure of any Materials. THE COMPANY may remove or alter any Materials at any time for any reason. We neither endorse nor are responsible for any opinion, advice, information,
                or statement made or displayed on the Service by any user. We are not responsible for any errors or omissions in articles or postings, for hyperlinks embedded in messages, or for any
                results obtained from the use of such information. Under no circumstances will THE COMPANY and/or its affiliates, suppliers, or agents be liable for any loss or damage caused by your
                reliance on such information obtained through the Service. We cannot and do not take responsibility for the veracity, reliability, or completeness of any opinion, advice, information,
                or statement available on the Service.
            </Typography>
            <Typography variant="body2" gutterBottom>
                b. The opinions expressed on the Service by users reflect solely the opinions of the users who post thereon and do not reflect the opinions of THE COMPANY.
                You acknowledge and agree that we have the right (but not the obligation) to monitor the Service and Materials; to alter or remove any Materials; and to disclose Materials
                and the circumstances surrounding their transmission to any third party in order to operate the Service properly; to protect ourselves, our sponsors, and our members and visitors;
                and to comply with legal obligations or governmental requests. If you believe an email violates our member policies, please contact THE COMPANY immediately so that we can consider its editing or removal.
            </Typography>
            <Typography variant="body2" gutterBottom>
                c. You are solely responsible for your Materials and the consequences of posting them on the Service. By posting Materials, you represent, warrant, and covenant that:
                (I) you are the creator and owner of the Materials or otherwise have sufficient rights and authority to grant the rights granted herein; (II) your Materials do not and will not
                (A) infringe, violate, or misappropriate any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity,
                or any other intellectual property or proprietary right or (B) slander, defame, or libel any other person; (III) your Materials do not contain any viruses, adware, spyware, worms,
                or other harmful or malicious code or (IV) unless you have received prior written authorization, your Materials specifically do not contain any confidential information of any third party.
                We reserve all rights and remedies against any users who breach these representations and warranties.
            </Typography>
            <Typography variant="body2" gutterBottom>
                6. Term and Termination
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. Term. The term of this Agreement (the “Term”) will commence on the date on which you first access or utilize the Service in any way (the “Effective Date”) and will continue
                so long as you continue to access or utilize the Service; provided that THE COMPANY reserves the right to take whatever lawful actions it may deem appropriate in response to actual
                or suspected violations of this Agreement including, without limitation, the suspension or termination of the user’s access and/or account, or blocking the user from access to the Service.
                THE COMPANY may cooperate with legal authorities and/or third parties in the investigation of any suspected or alleged crime or civil wrong. Except as may be expressly limited by the Privacy Policy,
                THE COMPANY reserves the right at all times to disclose any information as THE COMPANY deems necessary to satisfy any applicable law, regulation, legal process or governmental request, or to edit,
                refuse to post or to remove any information or materials, in whole or in part, in THE COMPANY’s sole discretion.
            </Typography>
            <Typography variant="body2" gutterBottom>
                b. Effect of Termination. The following Sections of this Agreement will survive termination or expiration of the Agreement: 1.c, 2.d, 3, 4, 5.e, 7.a, 8, 10.b, 11, 12, 13, 14, 15, 16, 18 – 22.
                Payments by you, which accrue or are due before termination of your contract, will continue to be payable by you, and amounts owed to THE COMPANY at the time of such termination or expiration
                will continue to be owed by you, after such expiration or termination.
            </Typography>
            <Typography variant="body2" gutterBottom>
                7. Ownership
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. Proprietary Information. You acknowledge and agree that: (I) the Service, including, without limitation, any Content, modifications, enhancements and updates,
                and any originals and copies thereof, in whole or in part, and all intellectual property rights therein (collectively, “Proprietary Information”), is owned by THE COMPANY and its licensors,
                as applicable; (II) the Proprietary Information contains valuable copyrighted and proprietary material of THE COMPANY; (III) the Proprietary Information is licensed, rather than sold,
                to you pursuant to this Agreement; and (IV) you have no rights in the Proprietary Information, other than the rights and licenses specifically granted to you pursuant to this Agreement.
            </Typography>
            <Typography variant="body2" gutterBottom>
                b. Trademarks. You acknowledge that THE COMPANY has acquired, and is the owner of, trademark rights in the name and word mark “ATOTO ”™ and in the other marks and design marks displayed
                on the Service. You acknowledge that this name and these marks are famous and internationally known. You will not, at any time or for any reason, challenge the validity of,
                or THE COMPANY’s ownership of, the foregoing name and marks, and you waive any rights you may have at any time to do so. All use of the foregoing name and marks by you will inure
                exclusively to the benefit of THE COMPANY. All marks shown on the Service but not owned by THE COMPANY are the property of their respective owners.
            </Typography>
            <Typography variant="body2" gutterBottom>
                8. Claims of Copyright Infringement.
            </Typography>
            <Typography variant="body2" gutterBottom>
                The Digital Millennium Copyright Act of 1998 (the “DMCA”) provides recourse for copyright owners who believe that material appearing on the Internet infringes their rights under U.S.
                copyright law. If you believe in good faith that materials hosted by THE COMPANY infringe your copyright, you (or your agent) may send us a notice requesting that the material be removed,
                or access to it blocked. The notice must include the following information:
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. a physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed;
            </Typography>
            <Typography variant="body2" gutterBottom>
                b. identification of the copyrighted work claimed to have been infringed (or if multiple copyrighted works located on the Service are covered by a single notification, a representative list of such works);
            </Typography>
            <Typography variant="body2" gutterBottom>
                c. identification of the material that is claimed to be infringing or the subject of infringing activity, and information reasonably sufficient to allow THE COMPANY to locate the material on the Service;
            </Typography>
            <Typography variant="body2" gutterBottom>
                d. the name, address, telephone number, and email address (if available) of the complaining party;
            </Typography>
            <Typography variant="body2" gutterBottom>
                e. a statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and
            </Typography>
            <Typography variant="body2" gutterBottom>
                f. a statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the
                owner of an exclusive right that is allegedly infringed.
            </Typography>
            <Typography variant="body2" gutterBottom>
                If you believe in good faith that a notice of copyright infringement has been wrongly filed against you, the DMCA permits you to send THE COMPANY a counter-notice. Notices and counter-notices
                must meet the then-current statutory requirements imposed by the DMCA; see www.copyright.gov for details.
            </Typography>
            <Typography variant="body2" gutterBottom>
                9. Disclaimer of Warranty.
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. THE SERVICE IS PROVIDED “AS IS” WITH ALL FAULTS, AND THE ENTIRE RISK AS TO SATISFACTORY QUALITY, PERFORMANCE, ACCURACY AND EFFORT IS WITH YOU. YOU ACKNOWLEDGE THAT,
                BECAUSE OF THE NATURE OF THE INTERNET, MOBILE NETWORKS, AND THE DEVICES WHICH ACCESS THE INTERNET AND/OR MOBILE NETWORKS, THE SERVICE MAY NOT BE ACCESSIBLE WHEN NEEDED,
                AND THAT INFORMATION, DATA, AUDIO AND VIDEO TRANSMITTED OVER THE INTERNET AND/OR MOBILE NETWORKS MAY BE SUBJECT TO INTERRUPTION OR THIRD PARTY INTERCEPTION AND MODIFICATION.
                THE COMPANY DISCLAIMS ANY AND ALL WARRANTIES OR CONDITIONS, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                QUIET ENJOYMENT, ACCURACY OF INFORMATIONAL CONTENT, AND ABSENCE OF VIRUSES AND DAMAGING OR DISABLING CODE. NONE OF THE COMPANY, ITS AFFILIATES OR SERVICE PROVIDERS,
                THE COMPANY’S CONTENT PROVIDERS AND/OR THEIR RESPECTIVE AFFILIATES, SUBSIDIARIES NOR THE EMPLOYEES, AGENTS AND/OR CONTRACTORS (COLLECTIVELY, THE “ATOTO PARTIES”) WARRANT THE ACCURACY,
                COMPLETENESS, OR TIMELINESS OF THE SERVICE. THE COMPANY CANNOT AND DOES NOT ASSUME ANY RESPONSIBILITY FOR ANY LOSS, DAMAGES OR LIABILITIES ARISING FROM THE FAILURE OF ANY TELECOMMUNICATIONS
                INFRASTRUCTURE, OR THE INTERNET OR FOR YOUR MISUSE OF ANY OF CONTENT AND INFORMATION ACCESSED THROUGH THE SERVICE. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SERVICE AND YOUR RELIANCE UPON ANY
                OF THE RESPECTIVE CONTENT IS AT YOUR SOLE RISK.
            </Typography>
            <Typography variant="body2" gutterBottom>
                10. Limitation of Liability.
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. EACH USER IS SOLELY RESPONSIBLE FOR (I) HIS OR HER USE OF THE SERVICE, AND (II) ANY DAMAGES INCURRED BY HIM OR HER OR ANY THIRD PARTY THAT ARISE FROM OR ARE RELATED TO THE SERVICE.
                THE AGGREGATE LIABILITY OF THE ATOTO PARTIES FOR ANY DAMAGES, WHETHER ARISING IN CONTRACT, TORT OR OTHERWISE, WILL BE LIMITED TO ACTUAL DAMAGES PROVED, AND NOT TO EXCEED THE AMOUNT PAID
                BY YOU TO THE COMPANY FOR THE SERVICE. NOTWITHSTANDING ANYTHING TO THE CONTRARY SET FORTH HEREIN, NONE OF THE ATOTO PARTIES WILL BE LIABLE FOR ANY PUNITIVE, SPECIAL, INDIRECT OR CONSEQUENTIAL
                DAMAGES, EVEN IF THE COMPANY OR ITS CONTENT PROVIDERS HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            </Typography>
            <Typography variant="body2" gutterBottom>
                b. NONE OF THE ATOTO PARTIES WILL HAVE ANY LIABILITY WHATSOEVER FOR ANY LOSSES; COSTS OR DAMAGES OF ANY KIND INCURRED OR SUFFERED BY YOU AS A RESULT OF SERVICES
                PROVIDED BY ANY OF THE THE COMPANY PROVIDERS ACCESSED THROUGH THE SERVICE.
            </Typography>
            <Typography variant="body2" gutterBottom>
                c. YOU REPRESENT THAT YOU HAVE INDEPENDENTLY INVESTIGATED THE ADVISABILITY OF USING THE LOCATION SERVICES MADE AVAILABLE TO YOU BY THE COMPANY AND THE POSSIBLE RISKS INVOLVED IN USING SUCH SERVICES.
                YOU AGREE TO MAINTAIN YOUR OWN INSURANCE COVERING SUCH RISKS AND WILL LOOK SOLELY TO SUCH INSURANCE FOR REIMBURSEMENT OF ANY RESULTING DAMAGES.
            </Typography>
            <Typography variant="body2" gutterBottom>
                11. Third Party Disputes.
            </Typography>
            <Typography variant="body2" gutterBottom>
                THE COMPANY IS NOT AFFILIATED WITH ANY CARRIER, SERVICE PROVIDER, OR THIRD PARTY SERVICE, AND ANY DISPUTE YOU HAVE WITH ANY CARRIER, SERVICE PROVIDER,
                THIRD PARTY SERVICE OR OTHER THIRD PARTY ARISING FROM YOUR USE OF THE SERVICE, INCLUDING, WITHOUT LIMITATION, ANY OTHER USER OF THE SERVICE OR YOUR EMPLOYER,
                IS DIRECTLY BETWEEN YOU AND SUCH THIRD PARTY, AND YOU IRREVOCABLY RELEASE THE COMPANY (AND OUR OFFICERS, DIRECTORS, AGENTS, SUBSIDIARIES, JOINT VENTURES, AND EMPLOYEES)
                FROM ANY AND ALL CLAIMS, DEMANDS AND DAMAGES (ACTUAL AND CONSEQUENTIAL) OF EVERY KIND AND NATURE, KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED WITH SUCH DISPUTES.
            </Typography>
            <Typography variant="body2" gutterBottom>
                12. Force Majeure.
            </Typography>
            <Typography variant="body2" gutterBottom>
                THE COMPANY will not be liable for any delay or failure to perform any obligation herein if the delay or failure is due to unforeseen events that are beyond THE COMPANY’s reasonable control,
                such as strikes, blockade, war, terrorism, riots, natural disasters, epidemic or governmental action, in so far as such an event prevents or delays THE COMPANY in fulfilling its obligations hereunder.
            </Typography>
            <Typography variant="body2" gutterBottom>
                13. Indemnification and Release.
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. You will defend, indemnify and hold THE COMPANY, its licensors and each such party’s parent organizations, subsidiaries, affiliates, officers, directors, members, employees,
                attorneys and agents, harmless against any loss or damage of any kind (including, without limitation, attorneys’ fees and lost revenues) arising from: (I) any and all breaches by
                you of this Agreement or any representation, warranty or covenant contained herein; (II) any and all use of the Service not specifically authorized hereunder and on the Service; and (III)
                any and all claims and actions against THE COMPANY by other parties to whom you allow access to the Service.
            </Typography>
            <Typography variant="body2" gutterBottom>
                b. You further waive, release and forever discharge the THE COMPANY Parties from any and all responsibility or liability for injuries or damages resulting from your Locations or any other service
                obtained through the use of the Service, including injuries or damages caused by the negligent act or omission of the Released Parties or in any way arising out of or connected with the Service.
            </Typography>
            <Typography variant="body2" gutterBottom>
                c. We reserve the right, at our own expense, to assume the exclusive defense and control of any matter otherwise subject to indemnification by you, and in such case, you agree to cooperate with
                our defense of such claim. You will not, in any event, settle any claim or matter without our written consent.
            </Typography>
            <Typography variant="body2" gutterBottom>
                14. Additional Service Features.
            </Typography>
            <Typography variant="body2" gutterBottom>
                The Service may contain information on products, and services provided by third parties, including its Providers, and links (including advertisements) to third party web sites.
                This information and these products, services, and links are provided only as a convenience to users. THE COMPANY does not review or control this information or these products,
                services, or other web sites, and THE COMPANY does not make any representations or warranties, express or implied, regarding this information or these products, services,
                or web sites. Inclusion of any of the foregoing in the Service does not constitute or imply an endorsement, authorization, sponsorship, or affiliation by or with THE COMPANY with
                respect to any third party or its web site or content, or any information, products, or services provided by that third party. THE COMPANY is under no obligation to maintain any link
                on the Service and may remove a link at any time in its sole discretion for any reason whatsoever.

            </Typography>
            <Typography variant="body2" gutterBottom>
                15. Dispute Resolution.
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. Mandatory Arbitration. Please read this carefully. It affects your rights. YOU AND THE COMPANY AND EACH OF OUR RESPECTIVE CORPORATE PARENTS, SUBSIDIARIES, AFFILIATES,
                PREDECESSORS IN INTEREST, SUCCESSORS, AND PERMITTED ASSIGNS AGREE TO ARBITRATION (EXCEPT FOR MATTERS THAT MAY BE TAKEN TO SMALL CLAIMS COURT), AS THE EXCLUSIVE FORM OF
                DISPUTE RESOLUTION EXCEPT AS PROVIDED FOR BELOW, FOR ALL DISPUTES AND CLAIMS ARISING OUT OF OR RELATING TO THIS AGREEMENT OR YOUR USE OF THE SERVICE.
                Arbitration uses a neutral arbitrator instead of a judge or jury, allows for more limited discovery than in court, and is subject to very limited review by courts.
                Please visit www.adr.org for more information about arbitration.
            </Typography>
            <Typography variant="body2" gutterBottom>
                I. Commencing Arbitration. A party seeking arbitration must first send to the other, by certified mail, a written notice of intent to arbitrate (a “Notice”),
                or, in the absence of a mailing address provided by you to THE COMPANY, to you via any other method available to THE COMPANY, including via e-mail.
                The Notice must (A) describe the nature and basis of the claim or dispute; and (B) set forth the specific relief sought (the “Demand”). If the claim is not resolved within
                30 days after the Notice is received, then you or THE COMPANY may commence an arbitration proceeding as set forth below or file a claim in small claims court.
                If you are required to pay a filing fee to commence arbitration against THE COMPANY, then THE COMPANY will promptly reimburse you for your confirmed payment of the filing fee
                upon THE COMPANY’s receipt of Notice at the Address that you have commenced arbitration along with a receipt for the filing fee, unless your Demand is equal to or greater than
                $1,000 or was filed in bad faith.
            </Typography>
            <Typography variant="body2" gutterBottom>
                II. Arbitration Proceeding. The arbitration will be in English. A single independent and impartial arbitrator with his or her primary place of business in New York County,
                New York, will be appointed pursuant to the Rules, as modified herein. You and THE COMPANY agree to comply with the following rules, which are intended to streamline the dispute
                resolution process and reduce the costs and burdens on the parties: (A) the arbitration will be conducted by telephone, online and/or be solely based on written submissions,
                the specific manner to be chosen by the party initiating the arbitration; (B) the arbitration will not require any personal appearance by the parties or witnesses unless otherwise
                mutually agreed in writing by the parties; and (C) any judgment on the award rendered by the arbitrator may be entered in any court of competent jurisdiction.
            </Typography>
            <Typography variant="body2" gutterBottom>
                III. No Class Actions. YOU AND THE COMPANY AGREE THAT YOU AND THE COMPANY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN YOUR OR ITS INDIVIDUAL CAPACITY AND NOT AS A PLAINTIFF OR CLASS
                MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE PROCEEDING. FURTHER, YOU AGREE THAT THE ARBITRATOR MAY NOT CONSOLIDATE PROCEEDINGS OF MORE THAN ONE PERSON’S CLAIMS, AND MAY NOT
                OTHERWISE PRESIDE OVER ANY FORM OF A REPRESENTATIVE OR CLASS PROCEEDING, AND THAT IF THIS SPECIFIC PROVISO IS FOUND TO BE UNENFORCEABLE, THEN THIS ENTIRE MANDATORY ARBITRATION
                SECTION WILL BE NULL AND VOID.
            </Typography>
            <Typography variant="body2" gutterBottom>
                IV. Decision of the Arbitrator. Barring extraordinary circumstances, the arbitrator will issue his or her decision within 120 days from the date the arbitrator is appointed.
                The arbitrator may extend this deadline by 30 days in the interests of justice. Arbitration proceedings will be closed to the public and confidential, and all related records will be
                permanently sealed, except as necessary to obtain court confirmation of the award. The award will be in writing and will include a statement setting forth the reasons for the claim’s
                disposition. The arbitrator will apply New York state law during the arbitration. You agree that these terms and your use of the App evidences a transaction involving interstate commerce. b.
                Equitable Relief. The foregoing provisions of this Section 15 do not apply to any claim in which THE COMPANY seeks equitable relief of any kind. You acknowledge that, in the event of a breach
                of this Agreement by THE COMPANY or any third party, the damage or harm, if any, caused to you will not entitle you to seek injunctive or other equitable relief against THE COMPANY, and your
                only remedy will be for monetary damages, subject to the limitations of liability set forth above.
            </Typography>
            <Typography variant="body2" gutterBottom>
                c. Claims. You and THE COMPANY agree that, notwithstanding any other rights the party may have under law or equity, any cause of action arising out of or related to this Agreement or the Service,
                excluding a claim for indemnification, must commence within one year after the cause of action accrues. Otherwise, such cause of action is permanently barred.
            </Typography>
            <Typography variant="body2" gutterBottom>
                d. Improperly Filed Claims. All claims you bring against THE COMPANY must be resolved in accordance with this Section 15. All claims filed or brought contrary to this Section 15
                will be considered improperly filed. Should you file a claim contrary to this Section 15, THE COMPANY may recover attorneys’ fees and costs up to $5,000, provided that THE COMPANY has
                notified you in writing of the improperly filed claim, and you have failed to promptly withdraw the claim.
            </Typography>
            <Typography variant="body2" gutterBottom>
                e. Modifications. If THE COMPANY changes the Mandatory Arbitration provision (other than a change to THE COMPANY’s Address), then you may reject any such change by sending us written
                notice to THE COMPANY’s Address within 30 days of the change, in which case your Account and your right to use the Service will terminate immediately, and this Section 18,
                as in effect immediately prior to the amendments you reject, will survive termination of this Agreement.
            </Typography>
            <Typography variant="body2" gutterBottom>
                16. Feedback.
            </Typography>
            <Typography variant="body2" gutterBottom>
                While our staff continually works to develop and evaluate our own product ideas and features, we pride ourselves on paying close attention to the interests, feedback, comments,
                and suggestions we receive from our Users. If you send us or our employees any ideas or suggestions for products, services, features, modifications, enhancements, content, promotions,
                strategies, or product/feature names, or any related materials (collectively “Feedback”), then regardless of what your communication may say, the following terms apply,
                to avoid future misunderstandings. By sending us Feedback, you agree that:
            </Typography>
            <Typography variant="body2" gutterBottom>
                a. THE COMPANY will own, exclusively, all known or later discovered rights to the Feedback;
            </Typography>
            <Typography variant="body2" gutterBottom>
                b. THE COMPANY will not be subject to any obligation of confidentiality and will not be liable for any use or disclosure of any Feedback; and
            </Typography>
            <Typography variant="body2" gutterBottom>
                c. THE COMPANY will be entitled to unrestricted use of the Feedback for any purpose whatsoever, commercial or otherwise, without compensation to you or any other person.
            </Typography>
            <Typography variant="body2" gutterBottom>
                17. Entire Agreement; Variation.
            </Typography>
            <Typography variant="body2" gutterBottom>
                This Agreement and any license contained in THE COMPANY’s products set forth the entire agreement between THE COMPANY and you with respect to the Service; provided, however,
                that: (1) THE COMPANY Providers will also be required to enter into the THE COMPANY Provider Agreement; (2) THE COMPANY may enter into additional agreements with you.
            </Typography>
            <Typography variant="body2" gutterBottom>
                18. Severability.
            </Typography>
            <Typography variant="body2" gutterBottom>
                If any provision of this Agreement is held invalid, illegal or unenforceable, such provisions will be modified, or if not possible, severed, to reflect the fullest valid,
                legal and enforceable expression of the intent of the parties and the remainder of this Agreement will not be affected thereby.
            </Typography>
            <Typography variant="body2" gutterBottom>
                19. Relationship of Parties.
            </Typography>
            <Typography variant="body2" gutterBottom>
                Nothing herein will be deemed to create an employer- employee relationship between THE COMPANY and you, nor any agency, joint venture or partnership relationship between the parties.
                Neither party will have the right to bind the other to any obligation, nor have the right to incur any liability on behalf of the other.
            </Typography>
            <Typography variant="body2" gutterBottom>
                20. Waiver.
            </Typography>
            <Typography variant="body2" gutterBottom>
                No delay, omission, or failure to exercise any right or remedy provided herein will be deemed to be a waiver thereof or an acquiescence in the event giving rise to such right or remedy,
                but every such right or remedy may be exercised, from time to time as may be deemed expedient by the party exercising such remedy or right.
            </Typography>
            <Typography variant="body2" gutterBottom>
                21. Assignment.
            </Typography>
            <Typography variant="body2" gutterBottom>
                Neither this Agreement nor any of the rights granted to you herein may be assigned or transferred by you, whether voluntarily or by operation of law, without the express prior written permission
                of THE COMPANY and any attempt to do so will be null and void. However, THE COMPANY may assign or transfer this Agreement at any time without your permission.
            </Typography>
            <Typography variant="body2" gutterBottom>
                22. Third-Party Beneficiaries.
            </Typography>
            <Typography variant="body2" gutterBottom>
                The provisions of this Agreement relating to the rights of THE COMPANY’s Content Providers are intended for the benefit of such Content Providers, and such Content Providers,
                as third-party beneficiaries, will be entitled to enforce such provisions in accordance with their terms, irrespective of the fact that they are not signatories to this Agreement.
            </Typography>

        </Container>
    );
};

export default UserDeletePage;
