import React, { useEffect, useState } from 'react';
import {
    Button, Select, FormControl, FormLabel, InputLabel, MenuItem, Autocomplete, TextField
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from '../common/components/LocalizationProvider';
import useForm from '../common/util/useForm';
import { useCatch } from '../reactHelper';
import { atotoRequest } from '../fetchFunc';
import LoadingButton from '@mui/lab/LoadingButton';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
    },
    flexRow: {
        flexDirection: 'row',
        gap: theme.spacing(2),
    },
    tips: {
        fontSize: theme.spacing(1.5),
    }
}));

const CarInfo = ({ onSubmit, onCancle, loading }) => {
    const classes = useStyles();
    const t = useTranslation();

    const [platformList, setPlatformList] = useState([])
    const [marketList, setMarketList] = useState([])
    const [yearList, setYearList] = useState([])
    const [brandList, setBrandList] = useState([])
    const [modelList, setModelList] = useState([])

    const { register, handleSubmit, formState: { errors, formData }, updataFormData, unregister } = useForm();

    const getPlatformList = useCatch(async () => {
        const res = await atotoRequest('/atoto-product-core/dict/queryListBy?dictParentCode=RELATED_PLAT')
        if (res.success && res.data) {
            setPlatformList(res.data)
        }
    })

    const getMarketList = useCatch(async () => {
        const res = await atotoRequest('/atoto-product-core/dict/queryTreeByTopDictKey/?dictKey=AMAZON_SITE')
        if (res.success && res.data) {
            setMarketList(res.data)
        }
    })

    const getYeartList = useCatch(async () => {
        const res = await atotoRequest('/atoto-product-search/advancedSearch/queryModelById?id=0')
        if (res.success && res.data) {
            setYearList(res.data)
        }
    })

    const getBrandList = useCatch(async (yearId) => {
        const res = await atotoRequest(`/atoto-product-search/advancedSearch/queryModelById?id=${yearId}`)
        if (res.success && res.data) {
            setBrandList(res.data)
        }
    })

    const getModelList = useCatch(async (brandId) => {
        const res = await atotoRequest(`/atoto-product-search/advancedSearch/queryModelById?id=${brandId}`)
        if (res.success && res.data) {
            setModelList(res.data)
        }
    })

    useEffect(() => {
        getPlatformList()
        getMarketList()
        getYeartList()
    }, [])

    useEffect(() => {
        updataFormData({ make: '', model: '' }, false)
        const yearId = yearList?.find((item) => item.modelName === formData['year'])?.id || ''
        if (yearId) getBrandList(yearId)
    }, [formData['year']])

    useEffect(() => {
        updataFormData({ model: '' }, false)
        const brandId = brandList?.find((item) => item.modelName === formData['make'])?.id || ''
        if (brandId) getModelList(brandId)
    }, [formData['make']])

    useEffect(() => {
        if (formData['hasBuy'] === 0) {
            unregister('platformKey')
            unregister('market')
            unregister('platformRemark')
        }

        if (formData['platformKey'] && formData['platformKey'] !== '2') {
            unregister('market')
        }

        if (formData['platformKey'] && formData['platformKey'] !== '9') {
            unregister('platformRemark')
        }
    }, [formData['hasBuy'], formData['platformKey']])

    return (
        <div className={classes.container}>

             <FormControl required>
                <InputLabel>{t('registerShoppingPlatform')}</InputLabel>
                <Select
                    label={t('registerShoppingPlatform')}
                    {...register('platformKey', { validate: (value) => !value })}
                >
                    {platformList.map(item => <MenuItem key={item.dictCode} value={item.dictCode}>{item.dictValue}</MenuItem>)}
                </Select>
            </FormControl>

            {formData['platformKey'] === '2' ? <FormControl>
                <InputLabel>{t('fromWhichWebsite')}</InputLabel>
                <Select
                    label={t('fromWhichWebsite')}
                    {...register('market', { validate: (value) => !value })}
                >
                    {marketList.map(item => <MenuItem key={item.dictCode} value={item.dictCode}>{item.dictValue}</MenuItem>)}
                </Select>
            </FormControl> : null}

            {formData['platformKey'] === '9' ?
                <TextField
                    label={t('platformRemarkLabel')}
                    multiline
                    rows={2}
                    {...register('platformRemark', { validate: (value) => !value })}
                />
                : null}

            <FormLabel filled>{t('vehicleType')}</FormLabel>

            <FormControl {...register('year', { validate: (value) => !value })}>
                <Autocomplete
                    freeSolo
                    value={formData['year']}
                    options={yearList.map((option) => option.modelName)}
                    renderInput={(params) =>
                        <TextField
                            error={!!errors['year']}
                            {...params}
                            label={t("year")}
                        />
                    }
                    onChange={(event, newValue) => updataFormData({ year: newValue || '' })}
                />
            </FormControl>
            <FormControl {...register('make', { validate: (value) => !value })}>
                <Autocomplete
                    freeSolo
                    value={formData['make']}
                    options={brandList.map((option) => option.modelName)}
                    renderInput={(params) =>
                        <TextField
                            error={!!errors['make']}
                            {...params}
                            label={t("brand")}
                        />
                    }
                    onChange={(event, newValue) => updataFormData({ make: newValue || '' })}
                />
            </FormControl>
            <FormControl {...register('model', { validate: (value) => !value })}>
                <Autocomplete
                    freeSolo
                    value={formData['model']}
                    options={modelList.map((option) => option.modelName)}
                    renderInput={(params) =>
                        <TextField
                            error={!!errors['model']}
                            {...params}
                            label={t("model")}
                        />
                    }
                    onChange={(event, newValue) => updataFormData({ model: newValue || '' })}
                />
            </FormControl>

            <FormLabel className={classes.tips}>{t('registerInformationTips')}</FormLabel>

            <FormControl className={classes.flexRow}>
                <Button
                    fullWidth
                    onClick={onCancle}
                >
                    {t('sharedCancel')}
                </Button>
                <LoadingButton
                    loading={loading}
                    variant="contained"
                    color="secondary"
                    onClick={() => handleSubmit(onSubmit)}
                    fullWidth
                >
                    {t('loginRegister')}
                </LoadingButton>
            </FormControl>

        </div>

    );
};

export default CarInfo;
