import { useTranslation } from '../common/components/LocalizationProvider';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    main: {
        margin: '200px auto',
        padding: 24,
        textAlign: 'center'
    }
}));

export default function CodeExpired() {
    const t = useTranslation();
    const classes = useStyles();

    return <div className={classes.main}>
        {t('codeExpired')}
    </div>
}