import React, { useState } from 'react';
import {
    Container, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
        "& .MuiTypography-gutterBottom": {
            marginBottom: theme.spacing(2),
        }
    },
    table: {
        borderCollapse: 'collapse',
    },
    td: {
        padding: theme.spacing(1),
    },
    wordBreak: {
        wordBreak: 'break-word',
    }
}));

const UserDeletePage = () => {
    const classes = useStyles();
    const theme = useTheme();

    return (
        <Container maxWidth="lg" className={classes.container}>

            <Typography variant="h6" align='center' gutterBottom>
                Privacy Policy
            </Typography>
            <Typography variant="body2" gutterBottom>
                This Privacy Policy applies to users of the ATOTO™ products and applications. AOTULE ELECTRONICS, INC.
                (“ATOTO”), a Shenzhen, China company, is the developer and the owner of all the commercial rights to the applications and products sold under the ATOTO™ Brand.

            </Typography>

            <Typography variant="body2" gutterBottom>
                AOTULE ELECTRONICS, INC. (“We”, “Our”, “ATOTO” or “The Company”) have updated our privacy policy to include additional information
                required under applicable Data protection regulations – including the European Union’s General Data Protection Regulation(GDPR) such as the following:
            </Typography>
            <Typography variant="body2" gutterBottom>
                <ul>
                    <li>Why we process personal data</li>
                    <li>Legal grounds for processing personal data</li>
                    <li>European union user rights under the GDPR and how to exercise them</li>
                    <li>Contact information for ATOTO data controllers</li>
                    <li>Contact information for the ATOTO data protection officer in the European union</li>
                </ul>
            </Typography>
            <Typography variant="body2" gutterBottom>
                Personal data that is processed when you create a ATOTO account: <br />
                When you create a ATOTO account, we ask you to provide your email address and name.
            </Typography>

            <Typography variant="body2" gutterBottom>
                PURPOSES AND LEGAL GROUND 1:
            </Typography>
            <Typography variant="body2" gutterBottom>
                (a) ATOTO processes your email address because you use your email address and your password to sign in to your account.
                The legal ground for processing your email address for this purpose is based on ATOTO’s legitimate interest in protecting the security of your account.
            </Typography>
            <Typography variant="body2" gutterBottom>
                (b) ATOTO also processes your email address for the purpose of sending you important information about your ATOTO products, apps or account,
                such as important safety information or material changes to this Privacy Policy. The name you provide is associated with your account profile,
                and is displayed when you submit comments or other material on a ATOTO website or app. The legal ground for processing your email address and name for these purposes is ATOTO’s
                legitimate interest in providing you important safety or other information about your ATOTO products, apps or account or material changes to this Privacy Policy,
                and in providing you an opportunity to engage with other ATOTO users.
            </Typography>
            <Typography variant="body2" gutterBottom>
                (c) If you provide your opt-in consent to receiving marketing information from ATOTO, ATOTO will also process your email address for the purpose of sending you marketing
                information about ATOTO products and apps. The legal ground for processing your email address for this purpose is your consent. You may withdraw your consent at any time
                by changing your preferences in your ATOTO account or through the unsubscribe link at the bottom of our marketing emails. The marketing emails you receive from ATOTO are based
                on the preferences you provide in your ATOTO account, the locale indicated by your Internet Protocol (IP) address, the types of ATOTO devices you have added to your ATOTO account,
                and any subscriptions included in your ATOTO account. The legal ground for processing this data for this purpose is ATOTO’s legitimate interest in reducing the number of marketing emails
                sent to each particular customer by selecting which customers receive a particular marketing email rather than sending every marketing email to every customer who has consented to
                receiving marketing emails.
            </Typography>
            <Typography variant="body2" gutterBottom>
                (d) ATOTO also processes your email address to associate it with your ATOTO account when you interact with our customer support representatives.
                The legal ground for this processing is our legitimate interest in providing quality customer support.
            </Typography>
            <Typography variant="body2" gutterBottom>
                Personal data that is processed when you sign in to your ATOTO account with Social Media Credentials if you choose:
            </Typography>
            <Typography variant="body2" gutterBottom>
                <ul>
                    <li>
                        You may choose to sign in to your ATOTO account using your social media sign in credentials (e.g., your Facebook sign in credentials).
                        If you choose to do this, the first time you do so you will be asked whether you agree that the social media provider may provide certain information to ATOTO,
                        such as your name, email address, profile photo, posts, comments and other information associated with your social media account.
                    </li>
                    <li>
                        All of this information is made available to ATOTO by the social media provider due to the way the social sign-on configuration works.
                        However, the only information ATOTO retains and processes is your email address. If you do not want your information to be shared with ATOTO by the social media provider,
                        then you can simply sign in to your ATOTO account using your ATOTO account credentials instead of your social media account credentials.
                    </li>
                </ul>
            </Typography>
            <Typography variant="body2" gutterBottom>
                PURPOSE AND LEGAL GROUND 2:
            </Typography>
            <Typography variant="body2" gutterBottom>
                <ul>
                    <li>
                        ATOTO associates the email address provided by the social media provider with your ATOTO account so that you can use your email address to sign in to
                        your ATOTO account in the future if you no longer wish to sign in using your social media account credentials. The legal ground for processing this information for
                        this purpose is ATOTO’s legitimate interest in providing you an alternative sign in method and in securing your account.
                    </li>
                    <li>
                        Personal data that is processed when you sync your ATOTO device:
                    </li>
                    <li>
                        When you sync your device, we log data about the transmission, such as the IP address used when syncing, the sync time and date, crash/diagnostic logs,
                        geographic location of the device, information about your device, information about the network used to sync (e.g., Wi-Fi or cellular) and device battery level.
                    </li>
                </ul>
            </Typography>
            <Typography variant="body2" gutterBottom>
                PURPOSE AND LEGAL GROUND 3:
            </Typography>
            <Typography variant="body2" gutterBottom>
                We process this information to help identify and resolve errors or syncing issues.
                The legal ground for processing this information for this purpose is ATOTO’s legitimate interest in resolving errors or syncing issues and providing quality product support.
            </Typography>
            <Typography variant="body2" gutterBottom>
                Personal data that is processed when you communicate with ATOTO:
            </Typography>
            <Typography variant="body2" gutterBottom>
                When you interact with our customer support representatives via email, telephone or online, we collect personal data, such as your name, phone number,
                email address and contact preferences; and information about the ATOTO products you own, such as their device ID and date of purchase. We also may create event logs
                that are useful in diagnosing product or app performance-related issues, and capture information relating to the support or service issue. To improve customer service,
                subject to applicable laws, we may also record and review conversations with customer support representatives, and analyze any feedback provided to us through voluntary customer surveys.
                With your consent, our customer support representatives may sign in to your ATOTO account, if appropriate, to help troubleshoot and resolve your issue.
            </Typography>
            <Typography variant="body2" gutterBottom>
                PURPOSE AND LEGAL GROUND 4:
            </Typography>
            <Typography variant="body2" gutterBottom>
                We use this information to provide you with customer and product support and to monitor the quality and types of customer and product support we provide to our customers.
                The legal ground for processing this information for these purposes is ATOTO’s legitimate interests in providing quality product support.
                The legal ground for signing in to your ATOTO account, if appropriate, to help troubleshoot and resolve your issue is consent, which you may withdraw.
            </Typography>
            <Typography variant="body2" gutterBottom>
                Personal data that is processed when you use your ATOTO GPS tracking services，including ATOTO TrackHU’s Android applications for in-car systems “TrackHU”,
                mobile applications “TrackHU Manager” and website located at https://gpstrack.myatoto.com.
            </Typography>
            <Typography variant="body2" gutterBottom>
                If you use an ATOTO device, app or website and provide your consent, then ATOTO will collect and upload from your device data such as location, speed, direction, and time and date of recording.
                If you provide your consent when asked, then ATOTO may also share this aggregated data with or sell this data to third parties to enhance the quality of the traffic,
                parking and other features enabled by content providers.
            </Typography>
            <Typography variant="body2" gutterBottom>
                PURPOSE AND LEGAL GROUND 5:
            </Typography>
            <Typography variant="body2" gutterBottom>
                This data is aggregated with data from other consenting users and is used to enhance the quality of our products and apps and the traffic, parking and other features enabled by ATOTO or third parties.
                The legal ground for processing this data for this purpose is consent, which you may withdraw at any time within the settings of your ATOTO device or app.
            </Typography>
            <Typography variant="body2" gutterBottom>
                Personal data that is processed when you order certain services on an ATOTO app or website:
            </Typography>
            <Typography variant="body2" gutterBottom>
                If you order certain services on an ATOTO app or website, then ATOTO will collect your name, email address. We do not view or store your payment card information a third party.
            </Typography>
            <Typography variant="body2" gutterBottom>
                PURPOSE AND LEGAL GROUND 6:
            </Typography>
            <Typography variant="body2" gutterBottom>
                We collect your name and email address so we can process your order and fulfill your purchase. The legal ground for processing your name and email address for these
                purposes is performance of a contract. We also process your personal data as part of our fraud detection processes. The legal ground for processing your personal data
                for that purpose is our legitimate interest in protecting ATOTO and our customers from attempts to engage in fraudulent transactions.
            </Typography>
            <Typography variant="body2" gutterBottom>
                Categories of Recipients of Personal Data
            </Typography>
            <Typography variant="body2">
                Other service providers:
            </Typography>
            <Typography variant="body2" gutterBottom>
                ATOTO uses cloud services from third parties to assist in sending emails. Those services track the activities associated with these emails, such as whether they were opened,
                whether links in the emails were clicked on, and whether purchases were made following clicks on those links. ATOTO uses this data to analyze the level of engagement with its emails.
                ATOTO uses third-party service providers to ship purchased products to customers and to help us better understand our customers’ needs.
            </Typography>
            <Typography variant="body2">
                Other disclosures:
            </Typography>
            <Typography variant="body2" gutterBottom>
                We may disclose personal data about you to others: (a) if we have your valid consent to do so; (b) to comply with a valid subpoena, legal order, court order, legal process,
                or other legal obligation; (c) to enforce any of our terms and conditions or policies; or (d) as necessary to pursue available legal remedies or defend legal claims.
                We may also transfer your personal data to an affiliate, a subsidiary or a third party in the event of any reorganization, merger, sale, joint venture, assignment,
                transfer or other disposition of all or any portion of ATOTO’s business, assets or stock, including, without limitation, in connection with any bankruptcy or similar proceeding,
                provided that any such entity that we transfer personal data to will not be permitted to process your personal data other than as described in this Privacy Policy without providing you notice and,
                if required by applicable laws, obtaining your consent.
            </Typography>
            <Typography variant="body2" gutterBottom>
                Transfers of Personal Data
            </Typography>
            <Typography variant="body2" gutterBottom>
                When you create a ATOTO account, add personal data in your account profile, or upload data to your ATOTO account,
                your personal data will be collected and stored on ATOTO servers, as configured per your ATOTO device.
            </Typography>
            <Typography variant="body2" gutterBottom>
                Cookies and Similar Technologies
            </Typography>
            <Typography variant="body2">
                Websites:
            </Typography>
            <Typography variant="body2" gutterBottom>
                To help analyze how you and other visitors navigate ATOTO TrackHU Manager website, and compile aggregate statistics about site usage and response rates, we,
                with assistance from third-party analytics service providers, collect certain information when you visit our site. This information includes IP address, geographic location of the device,
                browser type, browser language, date and time of your request, time(s) of your visit(s), page views and page elements (e.g., links) that you click. We may use cookies, pixel tags, web beacons,
                clear GIFs or other similar tools on our site or in our email messages to assist us in collecting and analyzing such information. We use this information to provide better, more relevant
                content on our site, to measure the effectiveness of advertisements, to identify and fix problems, and to improve your overall experience on our site. We may also engage one or more third-party
                service providers to provide online advertisements on our behalf. They may use a pixel tag or other similar technology to collect information about your visits to sites, and they may use that
                information to send you targeted advertisements.
            </Typography>
            <Typography variant="body2" gutterBottom>
                If you do not want information collected through the use of these technologies, there is a simple procedure in most browsers that
                allows you to automatically decline many of these technologies, or to be given the choice of declining or accepting them.
            </Typography>
            <Typography variant="body2" gutterBottom>
                ATOTO “TrackHU” and “TrackHU Manager” Applications
            </Typography>
            <Typography variant="body2" gutterBottom>
                <ul>
                    <li>
                        To protect your right to know, we display the relevant operating system permissions that the product may apply and use through the following list,
                        and you can manage these permissions according to actual needs.
                    </li>
                    <li>
                        Depending on product upgrades, types and purposes of application and use of permissions may change.
                        We will adjust the list in a timely manner to ensure that you are informed of the application and use of permissions in a timely manner.
                    </li>
                    <li>
                        Please be aware that for the purpose of business and product functions and security,
                        we may also use third-party SDKs, which may also apply for or use relevant operating system permissions.
                    </li>
                </ul>
            </Typography>
            <Typography variant="body2" gutterBottom>
                1. Android Runtime Permissions
            </Typography>
            <Typography variant="body2" gutterBottom>
                Runtime permissions, also known as dangerous permissions, grant an app additional access to restricted data and allow the app to perform restricted operations with a
                more significant effect on the system and other apps. Therefore, you must first request runtime permissions in the app before accessing restricted data or performing
                restricted operations. Below are the runtime permissions used by the “TrackHU” and “TrackHU Manager” apps and the third-party SDKs integrated into it:
            </Typography>
            <Typography variant="body2" gutterBottom>
                <table className={classes.table} border='1'>
                    <tr>
                        <td className={classes.td}>Permissions</td>
                        <td className={classes.td}>Permission function description</td>
                        <td className={classes.td}>Use scenario or purpose</td>
                    </tr>
                    <tr>
                        <td className={[classes.td, classes.wordBreak].join(' ')}>READ_PHONE_STATE</td>
                        <td className={classes.td}>Read status and identity of mobile phone or in-car devices.</td>
                        <td className={classes.td}>
                            Login, log device information collection and other functions <br /><br />
                            Note: Provide information such as reading device identification, please rest assured that this authority cannot monitor or obtain any of your call content and information
                        </td>
                    </tr>
                    <tr>
                        <td className={classes.td}>CAMERA</td>
                        <td className={classes.td}>Take a photo and scan the QR code from the mobile phone</td>
                        <td className={classes.td}>Avatar replacement function, scan code binding device function</td>
                    </tr>
                </table>
            </Typography>
            <Typography variant="body2" gutterBottom>
                2.Android Common System Permissions:
            </Typography>
            <Typography variant="body2" gutterBottom>
                This type of permission allows access to data and operations outside the application sandbox. However, the risks to user privacy and to the operations of other applications
                posed by these data and operations are very small. The following lists the common permissions used by the “TrackHU” and “TrackHU Manager” apps and integrated third-party SDKs:
            </Typography>
            <Typography variant="body2" gutterBottom>
                <table className={classes.table} border='1'>
                    <tr>
                        <td className={classes.td}>Permissions</td>
                        <td className={classes.td}>Use scenario or purpose</td>
                    </tr>
                    <tr>
                        <td className={[classes.td, classes.wordBreak].join(' ')}>INTERNET</td>
                        <td className={classes.td}>Allow the application to use network</td>
                    </tr>
                    <tr>
                        <td className={[classes.td, classes.wordBreak].join(' ')}>
                            CHANGE_NETWORK_STATE <br />
                            ACCESS_NETWORK_STATE
                        </td>
                        <td className={classes.td}>Reading of network status and information</td>
                    </tr>
                    <tr>
                        <td className={[classes.td, classes.wordBreak].join(' ')}>REQUEST_INSTALL_PACKAGES</td>
                        <td className={classes.td}>Upgrade installation</td>
                    </tr>
                    <tr>
                        <td className={[classes.td, classes.wordBreak].join(' ')}>GET_TASKS</td>
                        <td className={classes.td}>Function involved</td>
                    </tr>
                    <tr>
                        <td className={[classes.td, classes.wordBreak].join(' ')}>GET_TASKS</td>
                        <td className={classes.td} rowSpan={2}>Log Information Collection</td>
                    </tr>
                    <tr>
                        <td className={[classes.td, classes.wordBreak].join(' ')}>READ_LOGS</td>
                    </tr>
                </table>
            </Typography>
            <Typography variant="body2" gutterBottom>
                3.IOS App Permissions
            </Typography>
            <Typography variant="body2" gutterBottom>
            The following list the common permissions used by the “TrackHU Manager” app and integrated third-party SDKs:
            </Typography>
            <Typography variant="body2" gutterBottom>
            <table className={classes.table} border='1'>
                    <tr>
                        <td className={classes.td}>Permissions</td>
                        <td className={classes.td}>Permission function description</td>
                        <td className={classes.td}>Use scenario or purpose</td>
                    </tr>
                    <tr>
                        <td className={classes.td}>Privacy - Contacts Usage Description</td>
                        <td className={classes.td}>Reading content from the album </td>
                        <td className={classes.td}>
                        Used for selecting photos from the photo album and scanning QR codes
                        </td>
                    </tr>
                </table>
            </Typography>
            <Typography variant="body2" gutterBottom>
            ATOTO uses this data to improve the quality and functionality of our apps, to develop and market products and features 
            that best serve you and other users; and to help identify and fix app stability issues and other usability problems as quickly as possible.
            </Typography>
            <Typography variant="body2" gutterBottom>
            The legal ground for processing this analytical information is our legitimate interest in understanding how our customers interact with our products, 
            apps and websites so we can enhance the user experience and functionality of our products, apps and websites.
            </Typography>
            <Typography variant="body2" gutterBottom>
            Here are examples of third-party providers of analytics and similar services we currently use:
            </Typography>
            <Typography variant="body2" >
            ANALYTICS SERVICES:
            </Typography>
            <Typography variant="body2" gutterBottom>
            Google: Google Analytics is used to track site statistics and user demographics, interests and behavior on websites. Find out more information about how 
            this analytics information may be used, how to control the use of your information, and how to opt-out of having your data used by Google Analytics.
            </Typography>
            <Typography variant="body2" gutterBottom>
            Application Performance Monitoring:
            </Typography>
            <Typography variant="body2" gutterBottom>
            ATOTO uses various software tools to analyze and monitor the performance of our complex ecosystem. To provide such services, 
            our app receives basic request information, including IP address, necessary to detect and diagnose anomalous errors and response times.
            </Typography>
            <Typography variant="body2" gutterBottom>
            Social Networks:
            </Typography>
            <Typography variant="body2" gutterBottom>
            Third-party social networks that provide interactive plug-ins or social networking features on ATOTO websites or mobile apps, may use cookies or other methods (e.g., web beacons) 
            to gather information regarding your use of our websites and apps. The use of such information by a third party depends on the privacy policy available on that social network’s website, 
            which we encourage you to carefully review. Such third parties may use these cookies or other tracking methods for their own purposes by relating information about your use of our 
            site with any of your Personal Information that they may have. We may also obtain analytics information from social networks that help us measure the effectiveness of our content 
            and advertisements on social networks (e.g., impressions and clicks).
            </Typography>
            <Typography variant="body2" gutterBottom>
            Children
            </Typography>
            <Typography variant="body2" gutterBottom>
            The ATOTO “TrackHU” Application, “TrackHU Manager” Application and “TrackHU Manager” Website are not intended to offer service by anyone under the age of 13 years old. 
            When a parent and or legal guardian use Applications and Website to locate a child, the parent is acknowledging that any personal information transmitted to ATOTO is solely 
            the parents and not that of a child under the age of 13. We do not knowingly collect personal information from children under 13 years of age. We do not collect personally identifying 
            information on our Applications or Website such as name or address from anyone under or over 13 years of age. If we learn that we have collected personal information from a child under age 13, 
            we will delete that information as quickly as possible. If you believe that we might have any information from a child under age 13, please contact us through support.
            </Typography>
            <Typography variant="body2" gutterBottom>
            Privacy Policy Updates
            </Typography>
            <Typography variant="body2" gutterBottom>
            We may update this Privacy Policy from time to time as we add new products, apps and website, as we improve our current offerings and as technologies and laws change. 
            You can determine when this Privacy Policy was last revised by referring to the “Last updated” legend at the top of this page. Any changes will become effective upon our posting 
            of the revised Privacy Policy.
            </Typography>
            <Typography variant="body2" gutterBottom>
            We will provide notice to you if these changes are material and, where required by applicable law, we will obtain your consent. This notice will be provided by email 
            or by posting notice of the changes on the ATOTO websites and apps that link to this Privacy Policy.
            </Typography>
            <Typography variant="body2" gutterBottom>
            Retention of Personal Data
            </Typography>
            <Typography variant="body2" gutterBottom>
            We will retain your personal data as long as your ATOTO account is considered to be active.
            </Typography>
            <Typography variant="body2" gutterBottom>
            AOTULE ELECTRONICS, INC. (“ATOTO”), FLAT/RM 1405A 14/F, THE BELGIAN BANK BUILDING, NOS.721-725 NATHAN ROAD, MONGKOK, 
            as data controller is responsible for processing personal data that is collected from the ATOTO App and websites
            </Typography>

        </Container>
    );
};

export default UserDeletePage;
