import React, { useEffect, useState } from 'react';
import { useLocalization } from '../../common/components/LocalizationProvider';
import useQuery from '../../common/util/useQuery';
import EnPage from './UserDeletePage_en';
import JaPage from './UserDeletePage_ja';
import DePage from './UserDeletePage_de';
import FrPage from './UserDeletePage_fr';
import ItPage from './UserDeletePage_it';
import EsPage from './UserDeletePage_es';
import RuPage from './UserDeletePage_ru';

const UserDeletePage = () => {
    const { language, setLanguage } = useLocalization();
    const query = useQuery();

    const lang = query.get('lang')

    const langMap = {
        EN: 'en',
        JP: 'ja',
        DE: 'de',
        FR: 'fr',
        IT: 'it',
        ES: 'es',
        ZH: 'zh',
        RU: 'ru',
        AR: 'ar',
        PT: 'pt',
    }

    const pageMap = {
        en: <EnPage />,
        ja: <JaPage />,
        de: <DePage />,
        fr: <FrPage />,
        it: <ItPage />,
        es: <EsPage />,
        ru: <RuPage />,
    }

    useEffect(()=>{
        if(lang){
            setLanguage(langMap[lang] || 'en')
        }
    },[lang])

    return pageMap[language || ''] || <EnPage />;
};

export default UserDeletePage;
