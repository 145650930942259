import React, { useState } from 'react';
import {
    Container, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import userDelete_de1 from '../../resources/images/atoto/userDelete_de1.png';
import userDelete_de2 from '../../resources/images/atoto/userDelete_de2.png';
import userDelete_de3 from '../../resources/images/atoto/userDelete_de3.png';
import userDelete_de4 from '../../resources/images/atoto/userDelete_de4.png';
import userDelete_mobile_de1 from '../../resources/images/atoto/userDelete_mobile_de1.png';
import userDelete_mobile_de2 from '../../resources/images/atoto/userDelete_mobile_de2.png';
import userDelete_mobile_de3 from '../../resources/images/atoto/userDelete_mobile_de3.png';
import userDelete_mobile_de4 from '../../resources/images/atoto/userDelete_mobile_de4.png';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    img: {
        maxWidth: '100%',
    }
}));

const UserDeletePage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const phone = useMediaQuery(theme.breakpoints.down('md'));

    if (phone) return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            Konto -Stornierungshandbuch für die mobile Website
            </Typography>

            <Typography variant="h6" gutterBottom>
            Notiz:
            </Typography>
            <Typography variant="body2" gutterBottom>
            Um Ihr Trackhu -Manager -Konto zu deaktivieren oder zu kündigen, besuchen Sie bitte die offizielle ATOTO -Website als Trackhu -Manager und ATOTO, um dasselbe Konto für die Anmeldung zu verwenden.
            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Melden Sie sich auf der offiziellen ATOTO -Website (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ) anhand eines im Trackhu -Manager registrierten Kontos an.

            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_de1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_de2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. Melden Sie sich an und klicken Sie auf "Mehr sehen" {">"} "Mein Konto löschen" {">"} "Einreichen", um das Konto zu kündigen. (Siehe ①② in den Bildern)
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_de3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_de4} className={classes.img} />
            </Typography>
        </Container>
    )

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            Konto -Stornierungshandbuch für die PC -Website

            </Typography>

            <Typography variant="h6" gutterBottom>
            Notiz:
            </Typography>
            <Typography variant="body2" gutterBottom>
            Um Ihr Trackhu -Manager -Konto zu deaktivieren oder zu kündigen, besuchen Sie bitte die offizielle ATOTO -Website als Trackhu -Manager und ATOTO, um dasselbe Konto für die Anmeldung zu verwenden.

            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Melden Sie sich auf der offiziellen ATOTO -Website (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ) anhand eines im Trackhu -Manager registrierten Kontos an.

            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_de1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_de2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. Melden Sie sich an und klicken Sie auf "Mehr sehen" {">"} "Mein Konto löschen" {">"} "Einreichen", um das Konto zu kündigen. (Siehe ①② in den Bildern)

            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_de3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_de4} className={classes.img} />
            </Typography>
        </Container>
    );
};

export default UserDeletePage;
