export const prefixString = (prefix, value) => prefix + value.charAt(0).toUpperCase() + value.slice(1);

export const unprefixString = (prefix, value) => value.charAt(prefix.length).toLowerCase() + value.slice(prefix.length + 1);

export const validEmail = (email) => /(.+)@(.+)\.(.{2,})/.test(email)

/**
 * 用户密码校验，允许输入8位以上字母和数字的组合
 * @param password
 * @return Boolean
 * */
export function validPassword(password) {
    const regLength = /^[A-Za-z0-9]{8,}$/
    const regLetters = /[A-Za-z]{1,}/
    const regNumber = /[0-9]{1,}/
    return (
      regLength.test(password) &&
      regLetters.test(password) &&
      regNumber.test(password)
    )
  }
