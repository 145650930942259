import React, { useState } from 'react';
import {
    Container, Typography, useMediaQuery, useTheme,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import userDelete_fr1 from '../../resources/images/atoto/userDelete_fr1.png';
import userDelete_fr2 from '../../resources/images/atoto/userDelete_fr2.png';
import userDelete_fr3 from '../../resources/images/atoto/userDelete_fr3.png';
import userDelete_fr4 from '../../resources/images/atoto/userDelete_fr4.png';
import userDelete_mobile_fr1 from '../../resources/images/atoto/userDelete_mobile_fr1.png';
import userDelete_mobile_fr2 from '../../resources/images/atoto/userDelete_mobile_fr2.png';
import userDelete_mobile_fr3 from '../../resources/images/atoto/userDelete_mobile_fr3.png';
import userDelete_mobile_fr4 from '../../resources/images/atoto/userDelete_mobile_fr4.png';

const useStyles = makeStyles((theme) => ({
    container: {
        padding: theme.spacing(2),
    },
    img: {
        maxWidth: '100%',
    }
}));

const UserDeletePage = () => {
    const classes = useStyles();
    const theme = useTheme();
    const phone = useMediaQuery(theme.breakpoints.down('md'));

    if (phone) return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            Guide d'annulation du compte pour le site Web mobile

            </Typography>

            <Typography variant="h6" gutterBottom>
            Note:
            </Typography>
            <Typography variant="body2" gutterBottom>
            Pour désactiver ou annuler votre compte Trackhu Manager, veuillez visiter le site officiel d'ATOTO car le gestionnaire Trackhu et ATOTO utilisent le même compte pour la connexion.

            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Connectez-vous au site officiel d'ATOTO (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ) à l'aide d'un compte enregistré sur le gestionnaire Trackhu.
 
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_fr1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_fr2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. Connectez-vous et cliquez sur "Voir plus" {">"} "Supprimer mon compte" {">"} "Soumettre" pour annuler le compte. (Voir ①② sur les photos)
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_fr3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_mobile_fr4} className={classes.img} />
            </Typography>
        </Container>
    )

    return (
        <Container maxWidth="lg" className={classes.container}>
            <Typography variant="h5" align='center' gutterBottom>
            Guide d'annulation du compte pour le site Web PC

            </Typography>

            <Typography variant="h6" gutterBottom>
            Note:
            </Typography>
            <Typography variant="body2" gutterBottom>
            Pour désactiver ou annuler votre compte Trackhu Manager, veuillez visiter le site officiel d'ATOTO car le gestionnaire Trackhu et ATOTO utilisent le même compte pour la connexion.

            </Typography>

            <Typography variant="h6" gutterBottom>
                1. Connectez-vous au site officiel d'ATOTO (
                <a href="https://www.myatoto.com" target='_blank'>https://www.myatoto.com</a>
                ) à l'aide d'un compte enregistré sur le gestionnaire Trackhu.
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_fr1} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_fr2} className={classes.img} />
            </Typography>
            <Typography variant="h6" gutterBottom>
                2. .Connectez-vous et cliquez sur "Voir plus" {">"} "Supprimer mon compte" {">"} "Soumettre" pour annuler le compte. (Voir ①② sur les photos)

            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_fr3} className={classes.img} />
            </Typography>
            <Typography align='center' variant="body2" gutterBottom>
                <img src={userDelete_fr4} className={classes.img} />
            </Typography>
        </Container>
    );
};

export default UserDeletePage;
